import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import * as Yup from "yup";
import ActionsCopmonent from "../../components/actions";
import FormGroup from "../../components/formGroup";
import { getImageSrc, handleFileChange } from "../../hooks/file.hook";
import { ICampaign, ICampaignCreateDto, ICampaignUpdateDto } from "../../interfaces/campaign.interface";
import {
  createCampaignAsync,
  getAllCampaignsAsync,
  getCampaignByIdAsync,
  toggleCampaignAsync,
  updateCampaignAsync,
} from "../../services/campaign.service";

const createSchema = Yup.object().shape({
  title: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  metaTitle: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  slug: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  description: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  image: Yup.mixed<File>().when((files, schema) => {
    if (files.some((obj) => obj !== null && files[0] instanceof File))
      return schema
        .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024) // 1MB
        .test(
          "fileFormat",
          "Unsupported Format",
          (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
        );
    else return schema.notRequired();
  }),
});

const updateSchema = Yup.object().shape({
  title: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  metaTitle: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  slug: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  description: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  image: Yup.mixed<File>().when((files, schema) => {
    if (files.some((obj) => obj !== null && files[0] instanceof File))
      return schema
        .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024) // 1MB
        .test(
          "fileFormat",
          "Unsupported Format",
          (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
        );
    else return schema.notRequired();
  }),
});

function CampaignsPage(): JSX.Element {
  const [datas, setDatas] = useState<ICampaign[]>([]);
  const [data, setData] = useState<ICampaign | null>(null);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllCampaignsAsync());
  }, []);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const handleDelete = useCallback(async (id: number) => {
    await toggleCampaignAsync(id);
    await getAllData();
  }, []);

  const handleEdit = useCallback(async (id: number) => {
    setData(await getCampaignByIdAsync(id));
    setUpdateModal(true);
  }, []);

  const [colDefs] = useState<ColDef<ICampaign>[]>([
    { headerName: "Id", field: "id" as keyof ICampaign },
    { headerName: "Title", field: "title" as keyof ICampaign },
    { headerName: "Meta Title", field: "metaTitle" as keyof ICampaign },
    { headerName: "Slug", field: "slug" as keyof ICampaign },
    { headerName: "Description", field: "description" as keyof ICampaign },
    { headerName: "Created At", field: "createdAt" as keyof ICampaign },
    { headerName: "Is Disabled", field: "isDisabled" as keyof ICampaign },
    {
      headerName: "Image",
      field: "image" as keyof ICampaign,
      cellRenderer: (params: CustomCellRendererProps) =>
        params.value && (
          <img className="gridjs-search-img" src={getImageSrc("campaigns", params.value)} alt="Campaign" />
        ),
    },
    {
      headerName: "Actions",
      field: "id" as keyof ICampaign,
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionsCopmonent id={params.value} onUpdate={handleEdit} onDelete={handleDelete} />
      ),
    },
  ]);

  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Campaigns</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Campaigns</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={(): void => setCreateModal(true)}>
              + Create Campaign
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<ICampaign>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={updateModal}
        onAfterClose={(): void => setUpdateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Update Campaign</h2>
          <div className="my-1">
            <Formik<ICampaignUpdateDto>
              initialValues={{
                title: data?.title ?? "",
                metaTitle: data?.metaTitle ?? "",
                slug: data?.slug ?? "",
                description: data?.description ?? "",
                image: null,
              }}
              validationSchema={updateSchema}
              onSubmit={async (
                values: ICampaignUpdateDto,
                formikHelpers: FormikHelpers<ICampaignUpdateDto>,
              ): Promise<void> => {
                if (!data?.id) return;
                const response = await updateCampaignAsync(data.id, values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setUpdateModal(false);
                  setData(null);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, initialValues, setFieldValue }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup
                    name="title"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.title}
                    label="Title"
                    placeholder="Title"
                  />
                  <FormGroup
                    name="metaTitle"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.metaTitle}
                    label="Meta Title"
                    placeholder="Meta Title"
                  />
                  <FormGroup
                    name="slug"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.slug}
                    label="Slug"
                    placeholder="Slug"
                  />
                  <FormGroup
                    name="description"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.description}
                    label="Description"
                    placeholder="Description"
                  />
                  <FormGroup
                    name="image"
                    label="Image"
                    type="file"
                    onChange={(event): void => handleFileChange(event, setFieldValue)}
                    accept="image/png, image/jpeg"
                    placeholder="Image"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setUpdateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Campaign</h2>
          <div className="my-1">
            <Formik<ICampaignCreateDto>
              initialValues={{
                title: "",
                metaTitle: "",
                slug: "",
                description: "",
                image: null,
              }}
              validationSchema={createSchema}
              onSubmit={async (
                values: ICampaignCreateDto,
                formikHelpers: FormikHelpers<ICampaignCreateDto>,
              ): Promise<void> => {
                const response = await createCampaignAsync(values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setCreateModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, setFieldValue }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-2">
                  <FormGroup name="title" type="text" onChange={handleChange} label="Title" placeholder="Title" />
                  <FormGroup
                    name="metaTitle"
                    type="text"
                    onChange={handleChange}
                    label="Meta Title"
                    placeholder="Meta Title"
                  />
                  <FormGroup name="slug" type="text" onChange={handleChange} label="Slug" placeholder="Slug" />
                  <FormGroup
                    name="description"
                    type="text"
                    onChange={handleChange}
                    label="Description"
                    placeholder="Description"
                  />
                  <FormGroup
                    name="image"
                    label="Image"
                    type="file"
                    onChange={(event): void => handleFileChange(event, setFieldValue)}
                    accept="image/png, image/jpeg"
                    placeholder="Image"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setCreateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </section>
  );
}

export default CampaignsPage;
