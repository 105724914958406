import { ErrorMessage } from "formik";
import { IFormGroupProps } from "../../interfaces/global.interface";

function FormGroup({
  label,
  name,
  type,
  placeholder,
  accept,
  onChange,
  defaultValue,
  defaultChecked,
  multiple,
  step,
  readOnly,
  disabled
}: IFormGroupProps): JSX.Element {
  return (
    <div className={type == "checkbox" ? "form-check" : "form-group"}>
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <input
        type={type}
        name={name}
        defaultValue={defaultValue}
        defaultChecked={defaultChecked}
        className={type == "checkbox" ? "form-check-input" : "form-control"}
        placeholder={placeholder}
        accept={accept}
        onChange={onChange}
        step={step}
        multiple={multiple}
        readOnly={readOnly}
        disabled={disabled}
      />
      <div className="form-error">
        <ErrorMessage name={name} component="span" className="text-danger" />
      </div>
    </div>
  );
}

export default FormGroup;
