import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { handleValidationErrors } from "../hooks/errorhandle.hook";
import { appendFormData } from "../hooks/form.hook";
import { IUser, IUserCreateDto, IUserCreateResponse, IUserUpdateDto } from "../interfaces/user.interface";
import { getAntiForgeryTokenAsync } from "./auth.service";

export const getAllUsersAsync = async (): Promise<IUser[]> =>
  await axiosInstance
    .get<IUser[]>("/Users/GetUsers")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getUserByIdAsync = async (id: number): Promise<IUser> =>
  await axiosInstance
    .get<IUser>("Users/GetUser/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return {} as IUser;
    });

export const createUserAsync = async (
  postData: IUserCreateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<boolean> =>
  await axiosInstance
    .post<IUserCreateResponse>("/Users/CreateUser", {
      ...postData,
      roleIds: postData.roleIds.map((role) => role.value),
    })
    .then((response) => {
      if (response.data.succeeded) {
        toast.success("Data created successfuly");
        return true;
      } else {
        toast.error(response.data.errors.join(" "));
        return false;
      }
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return false;
    });

export const updateUserAsync = async (
  id: number,
  putData: IUserUpdateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<boolean> => {
  const token = await getAntiForgeryTokenAsync();

  const formData = new FormData();
  appendFormData(formData, { id, ...putData, roleIds: putData.roleIds.map((role) => role.value) });

  return await axiosInstance
    .put("Users/UpdateUser", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-XSRF-TOKEN": token,
      },
    })
    .then((response) => {
      if (response.data.succeeded) {
        toast.success("Data updated successfuly");
        return true;
      } else {
        toast.error(response.data.errors.join(" "));
        return false;
      }
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return false;
    });
};

export const toggleUserAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("Users/DeleteUser/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => {
      console.log(err);
    });
