import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import ReactSelect from "react-select";
import * as Yup from "yup";
import ActionsCopmonent from "../../components/actions";
import FormGroup from "../../components/formGroup";
import { IOption } from "../../interfaces/global.interface";
import { IRedeemCode, IRedeemCodeCreateDto } from "../../interfaces/redeemcode.interface";
import { getAllProductsAsync } from "../../services/product.service";
import {
  createRedeemCodeAsync,
  getAllRedeemCodesAsync,
  toggleRedeemCodeAsync,
} from "../../services/redeemcode.service";

const createSchema = Yup.object().shape({
  code: Yup.string()
    .min(3, "Code must be at least 3 characters long")
    .max(50, "Too Long!")
    .required("This field is required"),
  purchasePrice: Yup.number().min(0).required("Required!"),
  productId: Yup.number().required("This field is required"),
});

function RedeemCodesPage(): JSX.Element {
  const [datas, setDatas] = useState<IRedeemCode[]>([]);
  const [options, setOptions] = useState<IOption[]>([]);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllRedeemCodesAsync());
  }, []);

  const getProducts = useCallback(async () => {
    const products = await getAllProductsAsync();
    setOptions(
      products
        .filter((product) => !product.isDisabled && product.isRedemption)
        .map((product) => {
          return { label: product.title, value: product.id };
        }),
    );
  }, []);

  useEffect(() => {
    getAllData();
    getProducts();
  }, [getAllData, getProducts]);

  const handleDelete = useCallback(async (id: number) => {
    await toggleRedeemCodeAsync(id);
    await getAllData();
  }, []);

  const [colDefs] = useState<ColDef<IRedeemCode>[]>([
    { headerName: "Id", field: "id" as keyof IRedeemCode },
    { headerName: "Code", field: "code" as keyof IRedeemCode },
    { headerName: "Product Id", field: "productId" as keyof IRedeemCode },
    { headerName: "Created At", field: "createdAt" as keyof IRedeemCode },
    { headerName: "Purchase Price", field: "purchasePrice" as keyof IRedeemCode },
    { headerName: "Is Redeemed", field: "isRedeemed" as keyof IRedeemCode },
    { headerName: "Is Disabled", field: "isDisabled" as keyof IRedeemCode },
    {
      headerName: "Actions",
      field: "id" as keyof IRedeemCode,
      cellRenderer: (params: CustomCellRendererProps) => <ActionsCopmonent id={params.value} onDelete={handleDelete} />,
    },
  ]);

  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Redeem Codes</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Redeem Codes</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={(): void => setCreateModal(true)}>
              + Create Redeem Code
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<IRedeemCode>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Redeem Code</h2>
          <div className="my-1">
            <Formik<IRedeemCodeCreateDto>
              initialValues={{
                code: "",
                purchasePrice: 0,
                productId: null,
              }}
              validationSchema={createSchema}
              onSubmit={async (
                values: IRedeemCodeCreateDto,
                formikHelpers: FormikHelpers<IRedeemCodeCreateDto>,
              ): Promise<void> => {
                const postData = {
                  codes: [values.code],
                  productId: values.productId,
                  purchasePrice: values.purchasePrice,
                };
                const response = await createRedeemCodeAsync(postData, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setCreateModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, setFieldValue, handleChange, initialValues }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-2">
                  <FormGroup name="code" type="text" onChange={handleChange} label="Code" placeholder="Code" />
                  <div className="form-group">
                    <label className="form-label" htmlFor="productId">
                      Products
                    </label>
                    <ReactSelect
                      options={options}
                      onChange={(option): unknown => setFieldValue("productId", option?.value)}
                    />
                    <div className="form-error">
                      <ErrorMessage name="productId" component="span" className="text-danger" />
                    </div>
                  </div>
                  <FormGroup
                    name="purchasePrice"
                    type="number"
                    defaultValue={initialValues.purchasePrice}
                    onChange={handleChange}
                    label="Purchase Price"
                    placeholder="Purchase Price"
                    step={0.25}
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setCreateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </section>
  );
}

export default RedeemCodesPage;
