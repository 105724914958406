import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import ReactSelect from "react-select";
import * as Yup from "yup";
import ActionsCopmonent from "../../components/actions";
import { IOption } from "../../interfaces/global.interface";
import { IWorker, IWorkerCreateDto } from "../../interfaces/worker.interface";
import { getAllUsersAsync } from "../../services/user.service";
import {
  createWorkerAsync,
  getAllWorkersAsync,
  toggleWorkerAsync,
  toggleWorkerAtWorkAsync,
} from "../../services/worker.service";

const createSchema = Yup.object().shape({
  name: Yup.number().required("Required!"),
});

function WorkersPage(): JSX.Element {
  const [datas, setDatas] = useState<IWorker[]>([]);
  const [options, setOptions] = useState<IOption[]>([]);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllWorkersAsync());
  }, []);

  const getUsers = useCallback(async () => {
    const users = await getAllUsersAsync();
    setOptions(
      users
        .filter((user) => !user.isDisabled && datas.some((worker) => worker.userId != user.id))
        .map((user) => {
          return { label: user.firstName, value: user.id };
        }),
    );
  }, []);

  useEffect(() => {
    getAllData();
    getUsers();
  }, [getAllData, getUsers]);

  const handleDelete = useCallback(async (id: number) => {
    await toggleWorkerAsync(id);
    await getAllData();
  }, []);

  const handleStatus = useCallback(async (id: number) => {
    await toggleWorkerAtWorkAsync(id);
    await getAllData();
  }, []);

  const [colDefs] = useState<ColDef<IWorker>[]>([
    { headerName: "Id", field: "id" as keyof IWorker },
    { headerName: "User Id", field: "userId" as keyof IWorker },
    { headerName: "Is At Work", field: "isAtWork" as keyof IWorker },
    { headerName: "Is Disabled", field: "isDisabled" as keyof IWorker },
    {
      headerName: "Actions",
      field: "id" as keyof IWorker,
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionsCopmonent id={params.value} onStock={handleStatus} onDelete={handleDelete} />
      ),
    },
  ]);

  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Workers</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Workers</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={(): void => setCreateModal(true)}>
              + Create Worker
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<IWorker>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Worker</h2>
          <div className="my-1">
            <Formik<IWorkerCreateDto>
              initialValues={{
                userId: null,
              }}
              validationSchema={createSchema}
              onSubmit={async (
                values: IWorkerCreateDto,
                formikHelpers: FormikHelpers<IWorkerCreateDto>,
              ): Promise<void> => {
                if (!values.userId) return;
                const response = await createWorkerAsync(values.userId, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setCreateModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, setFieldValue }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="userId">
                      Users
                    </label>
                    <ReactSelect
                      options={options}
                      onChange={(option): unknown => setFieldValue("userId", option?.value)}
                    />
                    <div className="form-error">
                      <ErrorMessage name="userId" component="span" className="text-danger" />
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setCreateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </section>
  );
}

export default WorkersPage;
