import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import * as Yup from "yup";
import ActionsCopmonent from "../../components/actions";
import FormGroup from "../../components/formGroup";
import { IPromoCode, IPromoCodeCreateDto, IPromoCodeUpdateDto } from "../../interfaces/promocode.interface";
import {
  createPromoCodeAsync,
  getAllPromoCodesAsync,
  getPromoCodeByIdAsync,
  togglePromoCodeAsync,
  updatePromoCodeAsync,
} from "../../services/promocode.service";

const createSchema = Yup.object().shape({
  code: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  discountValue: Yup.number().moreThan(0).required("Required!"),
  description: Yup.string().min(3, "Too Short!").max(20, "Too Long!").notRequired(),
  maxUsageCount: Yup.number().moreThan(0).notRequired(),
  timesUsedCount: Yup.number().moreThan(0).notRequired(),
  startDate: Yup.date().notRequired(),
  endDate: Yup.date().notRequired(),
  isPercent: Yup.boolean().required(),
  maxDiscountValue: Yup.number().moreThan(0).notRequired(),
  maxOrderValue: Yup.number().moreThan(0).notRequired(),
});

const updateSchema = Yup.object().shape({
  code: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  discountValue: Yup.number().moreThan(0).required("Required!"),
  description: Yup.string().min(3, "Too Short!").max(20, "Too Long!").notRequired(),
  maxUsageCount: Yup.number().moreThan(0).notRequired(),
  timesUsedCount: Yup.number().moreThan(0).notRequired(),
  startDate: Yup.date().notRequired(),
  endDate: Yup.date().notRequired(),
  isPercent: Yup.boolean().required(),
  maxDiscountValue: Yup.number().moreThan(0).notRequired(),
  maxOrderValue: Yup.number().moreThan(0).notRequired(),
});

function PromoCodesPage(): JSX.Element {
  const [datas, setDatas] = useState<IPromoCode[]>([]);
  const [data, setData] = useState<IPromoCode | null>(null);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllPromoCodesAsync());
  }, []);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const handleDelete = useCallback(async (id: number) => {
    await togglePromoCodeAsync(id);
    await getAllData();
  }, []);

  const handleEdit = useCallback(async (id: number) => {
    setData(await getPromoCodeByIdAsync(id));
    setUpdateModal(true);
  }, []);

  const [colDefs] = useState<ColDef<IPromoCode>[]>([
    { headerName: "Id", field: "id" as keyof IPromoCode },
    { headerName: "Code", field: "code" as keyof IPromoCode },
    { headerName: "Discount Value", field: "discountValue" as keyof IPromoCode },
    { headerName: "Is Percent", field: "isPercent" as keyof IPromoCode },
    { headerName: "Description", field: "description" as keyof IPromoCode },
    { headerName: "Max Usage Count", field: "maxUsageCount" as keyof IPromoCode },
    { headerName: "Times Used Count", field: "timesUsedCount" as keyof IPromoCode },
    { headerName: "Created At", field: "createdAt" as keyof IPromoCode },
    { headerName: "Start Date", field: "startDate" as keyof IPromoCode },
    { headerName: "End Date", field: "endDate" as keyof IPromoCode },
    { headerName: "Is Disabled", field: "isDisabled" as keyof IPromoCode },
    { headerName: "Max Order Value", field: "maxOrderValue" as keyof IPromoCode },
    { headerName: "Max Discount Value", field: "maxDiscountValue" as keyof IPromoCode },
    {
      headerName: "Actions",
      field: "id" as keyof IPromoCode,
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionsCopmonent id={params.value} onUpdate={handleEdit} onDelete={handleDelete} />
      ),
    },
  ]);

  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Promo Codes</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Promo Codes</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={(): void => setCreateModal(true)}>
              + Create Promo Code
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<IPromoCode>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={updateModal}
        onAfterClose={(): void => setUpdateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Update Promo Code</h2>
          <div className="my-1">
            <Formik<IPromoCodeUpdateDto>
              initialValues={{
                code: data?.code ?? "",
                discountValue: data?.discountValue ?? 0,
                description: data?.description ?? undefined,
                maxUsageCount: data?.maxUsageCount ?? undefined,
                timesUsedCount: data?.timesUsedCount ?? undefined,
                startDate: data?.startDate ? String(data?.startDate).substring(0, 16) : undefined,
                endDate: data?.endDate ? String(data?.endDate).substring(0, 16) : undefined,
                isPercent: data?.isPercent ?? false,
                maxDiscountValue: data?.maxDiscountValue ?? undefined,
                maxOrderValue: data?.maxOrderValue ?? undefined,
              }}
              validationSchema={updateSchema}
              onSubmit={async (
                values: IPromoCodeUpdateDto,
                formikHelpers: FormikHelpers<IPromoCodeUpdateDto>,
              ): Promise<void> => {
                if (!data?.id) return;
                const response = await updatePromoCodeAsync(data.id, values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setUpdateModal(false);
                  setData(null);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, initialValues }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup
                    name="code"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.code}
                    label="Code"
                    placeholder="Code"
                  />
                  <FormGroup
                    name="discountValue"
                    type="number"
                    onChange={handleChange}
                    defaultValue={initialValues.discountValue}
                    label="Discount Value"
                    placeholder="Discount Value"
                    step={0.25}
                  />
                  <FormGroup
                    name="description"
                    onChange={handleChange}
                    defaultValue={initialValues.description}
                    type="text"
                    label="Description"
                    placeholder="Description"
                  />
                  <FormGroup
                    name="maxUsageCount"
                    type="number"
                    onChange={handleChange}
                    defaultValue={initialValues.maxUsageCount}
                    label="Max Usage Count"
                    placeholder="Max Usage Count"
                  />
                  <FormGroup
                    name="timesUsedCount"
                    type="number"
                    onChange={handleChange}
                    defaultValue={initialValues.timesUsedCount}
                    label="Times Used Count"
                    placeholder="Times Used Count"
                  />
                  <FormGroup
                    name="startDate"
                    type="datetime-local"
                    onChange={handleChange}
                    defaultValue={initialValues.startDate}
                    label="Start Date"
                    placeholder="Start Date"
                  />
                  <FormGroup
                    name="endDate"
                    type="datetime-local"
                    onChange={handleChange}
                    defaultValue={initialValues.endDate}
                    label="End Date"
                    placeholder="End Date"
                  />
                  <FormGroup
                    name="isPercent"
                    type="checkbox"
                    onChange={handleChange}
                    defaultChecked={initialValues.isPercent}
                    label="Is Percent"
                    placeholder="Is Percent"
                  />
                  <FormGroup
                    name="maxDiscountValue"
                    type="number"
                    onChange={handleChange}
                    defaultValue={initialValues.maxDiscountValue}
                    label="Max Discount Value"
                    placeholder="Max Discount Value"
                  />
                  <FormGroup
                    name="maxOrderValue"
                    type="number"
                    onChange={handleChange}
                    defaultValue={initialValues.maxOrderValue}
                    label="Max Order Value"
                    placeholder="Max Order Value"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setUpdateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Promo Code</h2>
          <div className="my-1">
            <Formik<IPromoCodeCreateDto>
              initialValues={{
                code: "",
                discountValue: 0,
                description: undefined,
                maxUsageCount: undefined,
                timesUsedCount: undefined,
                startDate: undefined,
                endDate: undefined,
                isPercent: false,
                maxDiscountValue: undefined,
                maxOrderValue: undefined,
              }}
              validationSchema={createSchema}
              onSubmit={async (
                values: IPromoCodeCreateDto,
                formikHelpers: FormikHelpers<IPromoCodeCreateDto>,
              ): Promise<void> => {
                const response = await createPromoCodeAsync(values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setCreateModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-2">
                  <FormGroup name="code" type="text" onChange={handleChange} label="Code" placeholder="Code" />
                  <FormGroup
                    name="discountValue"
                    type="number"
                    onChange={handleChange}
                    label="Discount Value"
                    placeholder="Discount Value"
                    step={0.25}
                  />
                  <FormGroup
                    name="description"
                    onChange={handleChange}
                    type="text"
                    label="Description"
                    placeholder="Description"
                  />
                  <FormGroup
                    name="maxUsageCount"
                    type="number"
                    onChange={handleChange}
                    label="Max Usage Count"
                    placeholder="Max Usage Count"
                  />
                  <FormGroup
                    name="timesUsedCount"
                    type="number"
                    onChange={handleChange}
                    label="Times Used Count"
                    placeholder="Times Used Count"
                  />
                  <FormGroup
                    name="startDate"
                    type="datetime-local"
                    onChange={handleChange}
                    label="Start Date"
                    placeholder="Start Date"
                  />
                  <FormGroup
                    name="endDate"
                    type="datetime-local"
                    onChange={handleChange}
                    label="End Date"
                    placeholder="End Date"
                  />
                  <FormGroup
                    name="isPercent"
                    type="checkbox"
                    onChange={handleChange}
                    label="Is Percent"
                    placeholder="Is Percent"
                  />
                  <FormGroup
                    name="maxDiscountValue"
                    type="number"
                    onChange={handleChange}
                    label="Max Discount Value"
                    placeholder="Max Discount Value"
                  />
                  <FormGroup
                    name="maxOrderValue"
                    type="number"
                    onChange={handleChange}
                    label="Max Order Value"
                    placeholder="Max Order Value"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setCreateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </section>
  );
}

export default PromoCodesPage;
