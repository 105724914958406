import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import ReactSelect from "react-select";
import * as Yup from "yup";
import ActionsCopmonent from "../../components/actions";
import FormGroup from "../../components/formGroup";
import { handleFileChanges } from "../../hooks/file.hook";
import { IOption } from "../../interfaces/global.interface";
import {
  IProduct,
  IProductCreateDto,
  IProductUpdateDto,
  IProductUpdateStockDto,
} from "../../interfaces/product.interface";
import { getAllParentCategoriesAsync } from "../../services/category.service";
import { getAllDeliveryTypeAsync } from "../../services/deliverytype.service";
import { getAllManufacturerAsync } from "../../services/manufacturer.service";
import {
  createProductAsync,
  getAllProductsAsync,
  getProductByIdAsync,
  toggleProductAsync,
  updateProductAsync,
  updateProductStockAsync,
} from "../../services/product.service";

const createSchema = Yup.object().shape({
  title: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
  metaTitle: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
  slug: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
  isPopular: Yup.boolean().required("Required!"),
  isRedemption: Yup.boolean().required("Required!"),
  price: Yup.number().moreThan(0).required("Required!"),
  score: Yup.number().min(0).required("Required!"),
  purchasePrice: Yup.number().min(0).required("Required!"),
  availableQuantity: Yup.number().min(0).required("Required!"),
  categoryId: Yup.number().min(0).required("Required!"),
  deliveryTypeId: Yup.number().notRequired(),
  manufacturerId: Yup.number().notRequired(),
  images: Yup.array()
    .of(
      Yup.mixed<File>()
        .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024 * 4) // 4MB
        .test(
          "fileFormat",
          "Unsupported Format",
          (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
        )
        .required("Required!"),
    )
    .min(1, "At least one image is required")
    .max(10, "No more than 10 images are allowed")
    .required("Required!"),
});

const updateSchema = Yup.object().shape({
  title: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
  metaTitle: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
  slug: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
  isPopular: Yup.boolean().required("Required!"),
  isRedemption: Yup.boolean().required("Required!"),
  price: Yup.number().moreThan(0).required("Required!"),
  score: Yup.number().min(0).required("Required!"),
  purchasePrice: Yup.number().min(0).required("Required!"),
  deliveryTypeId: Yup.number().notRequired(),
  manufacturerId: Yup.number().notRequired(),
});

const stockSchema = Yup.object().shape({
  quantity: Yup.number().min(0).required("Required!"),
});

function ProductsPage(): JSX.Element {
  const [datas, setDatas] = useState<IProduct[]>([]);
  const [deliveryTypeOptions, setDeliveryTypeOptions] = useState<IOption[]>([]);
  const [manufacturerOptions, setManufacturerOptions] = useState<IOption[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<IOption[]>([]);
  const [data, setData] = useState<IProduct | null>(null);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [stockModal, setStockModal] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllProductsAsync());
  }, []);

  const getCategories = useCallback(async () => {
    const categories = await getAllParentCategoriesAsync();
    setCategoryOptions(
      categories
        .filter((category) => !category.isDisabled)
        .map((category) => {
          return { label: category.title, value: category.id };
        }),
    );
  }, []);

  const getDeliveryTypes = useCallback(async () => {
    const deliveryTypes = await getAllDeliveryTypeAsync();
    setDeliveryTypeOptions(
      deliveryTypes
        .filter((deliveryType) => !deliveryType.isDisabled)
        .map((deliveryType) => {
          return { label: deliveryType.name, value: deliveryType.id };
        }),
    );
  }, []);

  const getManufacturers = useCallback(async () => {
    const manufacturers = await getAllManufacturerAsync();
    setManufacturerOptions(
      manufacturers
        .filter((manufacturers) => !manufacturers.isDisabled)
        .map((manufacturers) => {
          return { label: manufacturers.name, value: manufacturers.id };
        }),
    );
  }, []);

  useEffect(() => {
    getAllData();
    getCategories();
    getDeliveryTypes();
    getManufacturers();
  }, [getAllData, getDeliveryTypes, getManufacturers, getCategories]);

  const handleDelete = useCallback(async (id: number) => {
    await toggleProductAsync(id);
    await getAllData();
  }, []);

  const handleEdit = useCallback(async (id: number) => {
    setData(await getProductByIdAsync(id));
    setUpdateModal(true);
  }, []);

  const handleStock = useCallback(async (id: number) => {
    setData(await getProductByIdAsync(id));
    setStockModal(true);
  }, []);

  const [colDefs] = useState<ColDef<IProduct>[]>([
    { headerName: "Id", field: "id" as keyof IProduct },
    { headerName: "Title", field: "title" as keyof IProduct },
    { headerName: "Title", field: "metaTitle" as keyof IProduct },
    { headerName: "Slug", field: "slug" as keyof IProduct },
    { headerName: "Price", field: "price" as keyof IProduct },
    { headerName: "Purchase Price", field: "purchasePrice" as keyof IProduct },
    { headerName: "Score", field: "score" as keyof IProduct },
    { headerName: "Available Quantity", field: "availableQuantity" as keyof IProduct },
    { headerName: "Created At", field: "createdAt" as keyof IProduct },
    { headerName: "Is Popular", field: "isPopular" as keyof IProduct },
    { headerName: "Is Redemption", field: "isRedemption" as keyof IProduct },
    { headerName: "Is Disabled", field: "isDisabled" as keyof IProduct },
    {
      headerName: "Actions",
      field: "id" as keyof IProduct,
      cellRenderer: (params: CustomCellRendererProps) =>
        params.data.isRedemption ? (
          <ActionsCopmonent
            id={params.value}
            onUpdate={handleEdit}
            toStock={"../createredeemcode/" + params.value}
            onDelete={handleDelete}
          />
        ) : (
          <ActionsCopmonent id={params.value} onUpdate={handleEdit} onStock={handleStock} onDelete={handleDelete} />
        ),
    },
  ]);
  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Products</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Products</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={(): void => setCreateModal(true)}>
              + Create Product
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<IProduct>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={updateModal}
        onAfterClose={(): void => setUpdateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Update Product</h2>
          <div className="my-1">
            <Formik<IProductUpdateDto>
              initialValues={{
                title: data?.title ?? "",
                metaTitle: data?.metaTitle ?? "",
                slug: data?.slug ?? "",
                isPopular: data?.isPopular ?? false,
                isRedemption: data?.isRedemption ?? false,
                price: data?.price ?? 0,
                purchasePrice: data?.purchasePrice ?? 0,
                score: data?.score ?? 0,
                deliveryTypeId: data?.deliveryTypeId ?? null,
                manufacturerId: data?.manufacturerId ?? null,
              }}
              validationSchema={updateSchema}
              onSubmit={async (
                values: IProductUpdateDto,
                formikHelpers: FormikHelpers<IProductUpdateDto>,
              ): Promise<void> => {
                if (!data?.id) return;
                const response = await updateProductAsync(data.id, values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setUpdateModal(false);
                  setData(null);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, initialValues, setFieldValue }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup
                    name="title"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.title}
                    label="Title"
                    placeholder="Title"
                  />
                  <FormGroup
                    name="metaTitle"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.metaTitle}
                    label="Meta Title"
                    placeholder="Meta Title"
                  />
                  <FormGroup
                    name="slug"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.slug}
                    label="Slug"
                    placeholder="Slug"
                  />
                  <FormGroup
                    name="isPopular"
                    type="checkbox"
                    onChange={handleChange}
                    defaultChecked={initialValues.isPopular}
                    label="Is Popular"
                    placeholder="Is Popular"
                  />
                  <FormGroup
                    name="price"
                    type="number"
                    defaultValue={initialValues.price}
                    onChange={handleChange}
                    label="Price"
                    placeholder="Price"
                    step={0.00}
                  />
                  <FormGroup
                    name="score"
                    type="number"
                    defaultValue={initialValues.score}
                    onChange={handleChange}
                    label="Score"
                    placeholder="Score"
                    step={0.00}
                  />
                  <FormGroup
                    name="purchasePrice"
                    type="number"
                    defaultValue={initialValues.purchasePrice}
                    onChange={handleChange}
                    label="Purchase Price"
                    placeholder="Purchase Price"
                    step={0.00}
                  />
                  <div className="form-group">
                    <label className="form-label" htmlFor="deliveryTypeId">
                      Delivery Types
                    </label>
                    <ReactSelect
                      options={deliveryTypeOptions}
                      defaultValue={deliveryTypeOptions.find((option) => option.value === initialValues.deliveryTypeId)}
                      onChange={(option): unknown => setFieldValue("deliveryTypeId", option?.value)}
                    />
                    <div className="form-error">
                      <ErrorMessage name="deliveryTypeId" component="span" className="text-danger" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="manufacturerId">
                      Manufacturers
                    </label>
                    <ReactSelect
                      options={manufacturerOptions}
                      defaultValue={manufacturerOptions.find((option) => option.value === initialValues.manufacturerId)}
                      onChange={(option): unknown => setFieldValue("manufacturerId", option?.value)}
                    />
                    <div className="form-error">
                      <ErrorMessage name="manufacturerId" component="span" className="text-danger" />
                    </div>
                  </div>
                  <FormGroup
                    name="isRedemption"
                    type="checkbox"
                    onChange={handleChange}
                    defaultChecked={initialValues.isRedemption}
                    label="Is Redemption"
                    placeholder="Is Redemption"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setUpdateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Product</h2>
          <div className="my-1">
            <Formik<IProductCreateDto>
              initialValues={{
                title: "",
                metaTitle: "",
                slug: "",
                isPopular: false,
                isRedemption: false,
                price: 0,
                purchasePrice: 0,
                score: 0,
                availableQuantity: 0,
                categoryId: null,
                deliveryTypeId: null,
                manufacturerId: null,
                images: [],
              }}
              validationSchema={createSchema}
              onSubmit={async (
                values: IProductCreateDto,
                formikHelpers: FormikHelpers<IProductCreateDto>,
              ): Promise<void> => {
                const response = await createProductAsync(values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setCreateModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, setFieldValue, handleChange, initialValues }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup name="title" type="text" onChange={handleChange} label="Title" placeholder="Title" />
                  <FormGroup
                    name="metaTitle"
                    type="text"
                    onChange={handleChange}
                    label="Meta Title"
                    placeholder="Meta Title"
                  />
                  <FormGroup name="slug" type="text" onChange={handleChange} label="Slug" placeholder="Slug" />
                  <FormGroup
                    name="isPopular"
                    type="checkbox"
                    onChange={handleChange}
                    label="Is Popular"
                    placeholder="Is Popular"
                  />
                  <FormGroup
                    name="isRedemption"
                    type="checkbox"
                    onChange={handleChange}
                    label="Is Redemption"
                    placeholder="Is Redemption"
                  />
                  <FormGroup
                    name="price"
                    type="number"
                    defaultValue={initialValues.price}
                    onChange={handleChange}
                    label="Price"
                    placeholder="Price"
                    step="any"
                  />
                  <FormGroup
                    name="score"
                    type="number"
                    defaultValue={initialValues.score}
                    onChange={handleChange}
                    label="Score"
                    placeholder="Score"
                    step="any"
                  />
                  <FormGroup
                    name="purchasePrice"
                    type="number"
                    defaultValue={initialValues.purchasePrice}
                    onChange={handleChange}
                    label="Purchase Price"
                    placeholder="Purchase Price"
                    step="any"
                  />
                  <FormGroup
                    name="availableQuantity"
                    type="number"
                    defaultValue={initialValues.availableQuantity}
                    onChange={handleChange}
                    label="Available Quantity"
                    placeholder="Available Quantity"
                  />
                  <div className="form-group">
                    <label className="form-label" htmlFor="categoryId">
                      Categories
                    </label>
                    <ReactSelect
                      options={categoryOptions}
                      onChange={(option): unknown => setFieldValue("categoryId", option?.value)}
                    />
                    <div className="form-error">
                      <ErrorMessage name="categoryId" component="span" className="text-danger" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="deliveryTypeId">
                      Delivery Types
                    </label>
                    <ReactSelect
                      options={deliveryTypeOptions}
                      onChange={(option): unknown => setFieldValue("deliveryTypeId", option?.value)}
                    />
                    <div className="form-error">
                      <ErrorMessage name="deliveryTypeId" component="span" className="text-danger" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="manufacturerId">
                      Manufacturers
                    </label>
                    <ReactSelect
                      options={manufacturerOptions}
                      onChange={(option): unknown => setFieldValue("manufacturerId", option?.value)}
                    />
                    <div className="form-error">
                      <ErrorMessage name="manufacturerId" component="span" className="text-danger" />
                    </div>
                  </div>
                  <FormGroup
                    name="images"
                    label="Images"
                    type="file"
                    multiple
                    onChange={(event): void => handleFileChanges(event, setFieldValue)}
                    accept="image/png, image/jpeg"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setCreateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={stockModal}
        onAfterClose={(): void => setStockModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Update Stock</h2>
          <div className="my-1">
            <Formik<IProductUpdateStockDto>
              initialValues={{
                quantity: data?.availableQuantity ?? 0,
              }}
              validationSchema={stockSchema}
              onSubmit={async (
                values: IProductUpdateStockDto,
                formikHelpers: FormikHelpers<IProductUpdateStockDto>,
              ): Promise<void> => {
                if (!data?.id) return;
                const response = await updateProductStockAsync(data.id, values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setStockModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, initialValues }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup
                    name="quantity"
                    type="number"
                    onChange={handleChange}
                    defaultValue={initialValues.quantity}
                    label="Quantity"
                    placeholder="Quantity"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setStockModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </section>
  );
}

export default ProductsPage;
