import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import * as Yup from "yup";
import ActionsCopmonent from "../../components/actions";
import FormGroup from "../../components/formGroup";
import { IDeliveryType, IDeliveryTypeCreateDto, IDeliveryTypeUpdateDto } from "../../interfaces/deliverytype.interface";
import {
  getAllDeliveryTypeAsync,
  createDeliveryTypeAsync,
  toggleDeliveryTypeAsync,
  getDeliveryTypeByIdAsync,
  updateDeliveryTypeAsync,
} from "../../services/deliverytype.service";

const createSchema = Yup.object().shape({
  name: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
});

const updateSchema = Yup.object().shape({
  name: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
});

function DeliveryTypesPage(): JSX.Element {
  const [datas, setDatas] = useState<IDeliveryType[]>([]);
  const [data, setData] = useState<IDeliveryType | null>(null);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllDeliveryTypeAsync());
  }, []);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const handleDelete = useCallback(async (id: number) => {
    await toggleDeliveryTypeAsync(id);
    await getAllData();
  }, []);

  const handleEdit = useCallback(async (id: number) => {
    setData(await getDeliveryTypeByIdAsync(id));
    setUpdateModal(true);
  }, []);

  const [colDefs] = useState<ColDef<IDeliveryType>[]>([
    { headerName: "Id", field: "id" as keyof IDeliveryType },
    { headerName: "Name", field: "name" as keyof IDeliveryType },
    { headerName: "Is Disabled", field: "isDisabled" as keyof IDeliveryType },
    {
      headerName: "Actions",
      field: "id" as keyof IDeliveryType,
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionsCopmonent id={params.value} onUpdate={handleEdit} onDelete={handleDelete} />
      ),
    },
  ]);
  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Delivery Types</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Delivery Types</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={(): void => setCreateModal(true)}>
              + Create Delivery Type
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<IDeliveryType>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={updateModal}
        onAfterClose={(): void => setUpdateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Update Delivery Type</h2>
          <div className="my-1">
            <Formik
              initialValues={{
                name: data?.name ?? "",
              }}
              validationSchema={updateSchema}
              onSubmit={async (
                values: IDeliveryTypeUpdateDto,
                formikHelpers: FormikHelpers<IDeliveryTypeUpdateDto>,
              ): Promise<void> => {
                if (!data?.id) return;
                const response = await updateDeliveryTypeAsync(data.id, values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setUpdateModal(false);
                  setData(null);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, initialValues }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup
                    name="name"
                    type="text"
                    defaultValue={initialValues.name}
                    onChange={handleChange}
                    label="Name"
                    placeholder="Name"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setUpdateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Delivery Type</h2>
          <div className="my-1">
            <Formik
              initialValues={{
                name: "",
              }}
              validationSchema={createSchema}
              onSubmit={async (
                values: IDeliveryTypeCreateDto,
                formikHelpers: FormikHelpers<IDeliveryTypeCreateDto>,
              ): Promise<void> => {
                const response = await createDeliveryTypeAsync(values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setCreateModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup name="name" type="text" onChange={handleChange} label="Name" placeholder="Name" />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setCreateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </section>
  );
}

export default DeliveryTypesPage;
