import CreateRedeemCodePage from "../components/createRedeemCode";
import ProtectedRoute from "../components/protectedRoute";
import ProtectedRouteChild from "../components/protectedRouteChild";
import Auth from "../pages/auth";
import CampaignsPage from "../pages/campaign";
import CategoriesPage from "../pages/category";
import ConditionsPage from "../pages/condition";
import Dashboard from "../pages/dashboard";
import DeliveryTypesPage from "../pages/deliverytype";
import ManufacturersPage from "../pages/manufacturer";
import OperationStatusesPage from "../pages/operationstatus";
import OrderAdmin from "../pages/order";
import PageNotFound from "../pages/pagenotfound";
import PaymentTypesPage from "../pages/paymenttype";
import ProductsPage from "../pages/product";
import ProductDiscountsPage from "../pages/productDiscount";
import PromoCodesPage from "../pages/promocode";
import RedeemCodesPage from "../pages/redeemCode";
import RolesPage from "../pages/role";
import StreamersPage from "../pages/streamer";
import UsersPage from "../pages/user";
import WorkersPage from "../pages/worker";
import { RolesEnum } from "../types/auth.type";

export const ROUTES = [
  {
    path: "/",
    element: <Auth />,
  },
  {
    path: "/dashboard/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "",
        element: <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<Dashboard />} />,
      },
      {
        path: "index",
        element: <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<Dashboard />} />,
      },
      {
        path: "users",
        element: <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<UsersPage />} />,
      },
      {
        path: "products",
        element: (
          <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<ProductsPage />} />
        ),
      },
      {
        path: "categories",
        element: (
          <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<CategoriesPage />} />
        ),
      },
      {
        path: "orders",
        element: (
          <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<OrderAdmin />} />
        ),
      },
      {
        path: "deliverytypes",
        element: <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN]} element={<DeliveryTypesPage />} />,
      },
      {
        path: "manufacturers",
        element: (
          <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<ManufacturersPage />} />
        ),
      },
      {
        path: "promocodes",
        element: (
          <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<PromoCodesPage />} />
        ),
      },
      {
        path: "operationstatuses",
        element: (
          <ProtectedRouteChild
            requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]}
            element={<OperationStatusesPage />}
          />
        ),
      },
      {
        path: "conditions",
        element: (
          <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<ConditionsPage />} />
        ),
      },
      {
        path: "roles",
        element: <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<RolesPage />} />,
      },
      {
        path: "paymenttypes",
        element: (
          <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<PaymentTypesPage />} />
        ),
      },
      {
        path: "campaigns",
        element: (
          <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<CampaignsPage />} />
        ),
      },
      {
        path: "streamers",
        element: (
          <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<StreamersPage />} />
        ),
      },
      {
        path: "redeemcodes",
        element: (
          <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<RedeemCodesPage />} />
        ),
      },
      {
        path: "workers",
        element: (
          <ProtectedRouteChild requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]} element={<WorkersPage />} />
        ),
      },
      {
        path: "productdiscounts",
        element: (
          <ProtectedRouteChild
            requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]}
            element={<ProductDiscountsPage />}
          />
        ),
      },
      {
        path: "createredeemcode/:productId",
        element: (
          <ProtectedRouteChild
            requiredRoles={[RolesEnum.ADMIN, RolesEnum.MODERATOR]}
            element={<CreateRedeemCodePage />}
          />
        ),
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];
