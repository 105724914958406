import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { IRedeemCodesCreateDto } from "../../interfaces/redeemcode.interface";
import { createRedeemCodeAsync } from "../../services/redeemcode.service";
import FormGroup from "../formGroup";

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .min(3, "Code must be at least 3 characters long")
    .max(50, "Too Long!")
    .required("This field is required"),
});

function CreateRedeemCodePage(): JSX.Element {
  const [codes, setCodes] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { productId } = useParams();

  interface IErrorInp {
    errors: string[];
  }

  const validateInput = async (target: EventTarget & HTMLInputElement): Promise<void> => {
    try {
      await validationSchema.validate({ code: target.value });
      const value = target.value.trim();
      if (!codes.includes(value)) {
        setCodes([...codes, value]);
        target.value = "";
        setErrorMessage("");
      } else {
        setErrorMessage("This code has already been used!");
      }
    } catch (err) {
      const error = err as IErrorInp;
      if (error.errors) {
        setErrorMessage(error.errors[0]);
      }
    }
  };

  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Add Redeem Codes</h1>
      </div>
      <div className="card custom-card">
        <div className="card-body">
          <Formik<IRedeemCodesCreateDto>
            initialValues={{
              codes: codes,
              purchasePrice: 0,
              productId: null,
            }}
            onSubmit={async (
              values: IRedeemCodesCreateDto,
              formikHelpers: FormikHelpers<IRedeemCodesCreateDto>,
            ): Promise<void> => {
              values.codes = codes;
              values.productId = Number(productId);
              const response = await createRedeemCodeAsync(values, formikHelpers.setFieldError);

              if (response) {
                formikHelpers.resetForm();
                navigate("../products");
              }
            }}
          >
            {({ handleSubmit, initialValues, handleChange }): JSX.Element => (
              <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-between h-100">
                <div className="d-flex gap-3">
                  <div className="form-group d-flex flex-column gap-1 codes-area">
                    <label className="form-label" htmlFor="codes">
                      Codes
                    </label>
                    <ol className="ml-3 border border-primary">
                      {codes.map((code, index) => (
                        <li key={index} className="p-1">
                          <div>
                            <span>
                              {index + 1}. {code}
                            </span>{" "}
                            <button
                              onClick={(): void =>
                                setCodes((prevDatas) => prevDatas.filter((prevData) => prevData !== code))
                              }
                              className="btn btn-sm btn-danger-light text-danger"
                            >
                              X
                            </button>
                          </div>
                        </li>
                      ))}
                    </ol>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="code">
                      Code
                    </label>
                    <input
                      type="text"
                      name="code"
                      onKeyDown={async (e): Promise<void> => {
                        if (e.keyCode == 13) {
                          e.preventDefault();
                          await validateInput(e.currentTarget);
                        }
                      }}
                      placeholder="Code"
                      className="form-control"
                    />
                    <div className="form-error">
                      <span className="text-danger">{errorMessage}</span>
                    </div>
                  </div>
                  <FormGroup
                    name="purchasePrice"
                    type="number"
                    defaultValue={initialValues.purchasePrice}
                    onChange={handleChange}
                    label="Purchase Price"
                    placeholder="Purchase Price"
                    step={0.25}
                  />
                </div>
                <div className="d-flex flex-row-reverse gap-2">
                  <Link to="../products" className="btn btn-light">
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    Create
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
}

export default CreateRedeemCodePage;
