import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { handleValidationErrors } from "../hooks/errorhandle.hook";
import { appendFormData } from "../hooks/form.hook";
import {
  IImageDto,
  IProduct,
  IProductCreateDto,
  IProductDiscount,
  IProductDiscountCreateDto,
  IProductDiscountUpdateDto,
  IProductUpdateDto,
  IProductUpdateStockDto,
} from "../interfaces/product.interface";
import { getAntiForgeryTokenAsync } from "./auth.service";

export const getAllProductsAsync = async (): Promise<IProduct[]> =>
  await axiosInstance
    .get<IProduct[]>("/Products/GetProducts")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getAllProductDiscountsAsync = async (): Promise<IProductDiscount[]> =>
  await axiosInstance
    .get<IProductDiscount[]>("/Products/GetProductDiscounts")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getProductByIdAsync = async (id: number): Promise<IProduct> =>
  await axiosInstance
    .get<IProduct>("Products/GetProduct/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return {} as IProduct;
    });

export const getProductDiscountByIdAsync = async (id: number): Promise<IProductDiscount> =>
  await axiosInstance
    .get<IProductDiscount>("Products/GetProductDiscount/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return {} as IProductDiscount;
    });

export const createProductAsync = async (
  postData: IProductCreateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> => {
  const token = await getAntiForgeryTokenAsync();

  const formData = new FormData();
  appendFormData(formData, {
    ...postData,
    images: postData.images.map(
      (image, index) => ({ hTMLContent: "img" + index, isMain: false, sortOrder: index, image: image }) as IImageDto,
    ),
  });

  return await axiosInstance
    .post("/Products/CreateProduct", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-XSRF-TOKEN": token,
      },
    })
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });
};

export const createProductDiscountAsync = async (
  postData: IProductDiscountCreateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> =>
  await axiosInstance
    .post("/Products/CreateProductDiscount", { ...postData })
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });

export const updateProductStockAsync = async (
  id: number,
  putData: IProductUpdateStockDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<boolean> =>
  await axiosInstance
    .put("Products/UpdateProductStock", { id, ...putData })
    .then(() => {
      toast.success("Data updated successfuly");
      return true;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return false;
    });

export const updateProductDiscountAsync = async (
  id: number,
  putData: IProductDiscountUpdateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<boolean> =>
  await axiosInstance
    .put("Products/UpdateProductDiscount", { id, ...putData })
    .then(() => {
      toast.success("Data updated successfuly");
      return true;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return false;
    });

export const updateProductAsync = async (
  id: number,
  putData: IProductUpdateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<boolean> =>
  await axiosInstance
    .put("Products/UpdateProduct", { id, ...putData })
    .then(() => {
      toast.success("Data updated successfuly");
      return true;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return false;
    });

export const toggleProductAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("Products/DeleteProduct/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => {
      console.log(err);
    });

export const toggleProductDiscountAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("Products/DeleteProductDiscount/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => {
      console.log(err);
    });
