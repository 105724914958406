import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { handleValidationErrors } from "../hooks/errorhandle.hook";
import { IStreamer, IStreamerCreateDto, IStreamerUpdateDto } from "../interfaces/streamer.interface";
import { getAntiForgeryTokenAsync } from "./auth.service";

export const getAllStreamersAsync = async (): Promise<IStreamer[]> =>
  await axiosInstance
    .get<IStreamer[]>("/Streamers/GetStreamers")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getStreamerByIdAsync = async (id: number): Promise<IStreamer> =>
  await axiosInstance
    .get<IStreamer>("Streamers/GetStreamer/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return {} as IStreamer;
    });

export const createStreamerAsync = async (
  postData: IStreamerCreateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> => {
  const token = await getAntiForgeryTokenAsync();
  return await axiosInstance
    .post(
      "/Streamers/CreateStreamer",
      { ...postData },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-XSRF-TOKEN": token,
        },
      },
    )
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });
};

export const updateStreamerAsync = async (
  id: number,
  putData: IStreamerUpdateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<boolean> => {
  const token = await getAntiForgeryTokenAsync();
  return await axiosInstance
    .put(
      "Streamers/UpdateStreamer",
      { id, ...putData },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-XSRF-TOKEN": token,
        },
      },
    )
    .then(() => {
      toast.success("Data updated successfuly");
      return true;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return false;
    });
};

export const toggleStreamerAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("Streamers/DeleteStreamer/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => {
      console.log(err);
    });
