import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { handleValidationErrors } from "../hooks/errorhandle.hook";
import { IWorker } from "../interfaces/worker.interface";

export const getAllWorkersAsync = async (): Promise<IWorker[]> =>
  await axiosInstance
    .get<IWorker[]>("/Workers/GetWorkers")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getWorkerByIdAsync = async (id: number): Promise<IWorker> =>
  await axiosInstance
    .get<IWorker>("Workers/GetWorker/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return {} as IWorker;
    });

export const createWorkerAsync = async (
  userId: number,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> =>
  await axiosInstance
    .post("/Workers/CreateWorker/" + userId)
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });

export const switchWorkerAtWorkAsync = async (): Promise<boolean> =>
  await axiosInstance
    .put("Workers/SwitchWorkerAtWork")
    .then((response) => {
      response.data
        ? toast.success("Your status is set to at work.")
        : toast.success("Your status is set to not at work.");
      return response.data;
    })
    .catch((err) => console.log(err));

export const toggleWorkerAtWorkAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .put("Workers/ToggleWorkerAtWork/" + id)
    .then(() => {
      toast.success("Toggle Worker status successfuly");
    })
    .catch((err) => console.log(err));

export const toggleWorkerAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("Workers/DeleteWorker/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => console.log(err));
