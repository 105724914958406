export interface IIdentityUser {
  sub: string;
  name: string;
  email: string;
  roles: string[];
  workerId?: number;
  isAtWork?: boolean;
}

export interface IIdentityUserDto {
  sub: string;
  name: string;
  email: string;
  role: string[];
  workerId?: number;
  isAtWork?: boolean;
}
export interface IIdentityResult {
  errors: string[];
  succeeded: boolean;
  expiryDate?: Date;
  token?: string;
}

export interface ILoginDto {
  email: string;
  password: string;
}

export interface ILoginResponse {
  succeeded: boolean;
  token?: string;
  expiryDate?: Date;
}

// ===================================================
export interface IRegisterDto {
  username: string;
  email: string;
  password: string;
  agreePolicy: boolean;
}
export interface IUpdateRoleDto {
  username: string;
  newRole: string;
}

export interface IAuthUser {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  createdAt: string;
  roles: string[];
}

export interface ILoginResponseDto {
  newToken: string;
  userInfo: IAuthUser;
}

export interface IAuthContextState {
  isAuthenticated: boolean;
  isAuthLoading: boolean;
  user?: IAuthUser;
}

export enum IAuthContextActionTypes {
  INITIAL = "INITIAL",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}

export interface IAuthContextAction {
  type: IAuthContextActionTypes;
  payload?: IAuthUser;
}

export interface IAuthContext {
  isAuthenticated: boolean;
  isAuthLoading: boolean;
  user?: IAuthUser;
  login: (username: string, password: string) => Promise<void>;
  register: (username: string, email: string, password: string) => Promise<void>;
  logout: () => void;
}

export enum RolesEnum {
  ADMIN = "Administrator",
  MODERATOR = "Moderator",
}
