import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import ReactSelect from "react-select";
import * as Yup from "yup";
import ActionsCopmonent from "../../components/actions";
import FormGroup from "../../components/formGroup";
import { IOption } from "../../interfaces/global.interface";
import {
  IProductDiscount,
  IProductDiscountCreateDto,
  IProductDiscountUpdateDto,
} from "../../interfaces/product.interface";
import {
  createProductDiscountAsync,
  getAllProductDiscountsAsync,
  getAllProductsAsync,
  getProductDiscountByIdAsync,
  toggleProductDiscountAsync,
  updateProductDiscountAsync,
} from "../../services/product.service";

const createSchema = Yup.object().shape({
  discountValue: Yup.number().moreThan(0).required("Required!"),
  description: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  startDate: Yup.date().notRequired(),
  endDate: Yup.date().notRequired(),
  isPercent: Yup.boolean().required("Required!"),
  isDefault: Yup.boolean().required("Required!"),
  productId: Yup.number().notRequired(),
});

const updateSchema = Yup.object().shape({
  discountValue: Yup.number().moreThan(0).required("Required!"),
  description: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  startDate: Yup.date().notRequired(),
  endDate: Yup.date().notRequired(),
  isPercent: Yup.boolean().required("Required!"),
  isDefault: Yup.boolean().required("Required!"),
});

function ProductDiscountsPage(): JSX.Element {
  const [datas, setDatas] = useState<IProductDiscount[]>([]);
  const [data, setData] = useState<IProductDiscount | null>(null);
  const [options, setOptions] = useState<IOption[]>([]);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllProductDiscountsAsync());
  }, []);

  const getProducts = useCallback(async () => {
    const products = await getAllProductsAsync();
    setOptions(
      products
        .filter((product) => !product.isDisabled)
        .map((product) => {
          return { label: product.title, value: product.id };
        }),
    );
  }, []);

  useEffect(() => {
    getAllData();
    getProducts();
  }, [getAllData, getProducts]);

  const handleDelete = useCallback(async (id: number) => {
    await toggleProductDiscountAsync(id);
    await getAllData();
  }, []);

  const handleEdit = useCallback(async (id: number) => {
    setData(await getProductDiscountByIdAsync(id));
    setUpdateModal(true);
  }, []);

  const [colDefs] = useState<ColDef<IProductDiscount>[]>([
    { headerName: "Id", field: "id" as keyof IProductDiscount },
    { headerName: "Discount Value", field: "discountValue" as keyof IProductDiscount },
    { headerName: "Product Id", field: "productId" as keyof IProductDiscount },
    { headerName: "Description", field: "description" as keyof IProductDiscount },
    { headerName: "Start Date", field: "startDate" as keyof IProductDiscount },
    { headerName: "End Date", field: "endDate" as keyof IProductDiscount },
    { headerName: "Is Percent", field: "isPercent" as keyof IProductDiscount },
    { headerName: "Is Default", field: "isDefault" as keyof IProductDiscount },
    { headerName: "Is Disabled", field: "isDisabled" as keyof IProductDiscount },
    {
      headerName: "Actions",
      field: "id" as keyof IProductDiscount,
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionsCopmonent id={params.value} onUpdate={handleEdit} onDelete={handleDelete} />
      ),
    },
  ]);

  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Product Discounts</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Product Discounts</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={(): void => setCreateModal(true)}>
              + Create Product Discount
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<IProductDiscount>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={updateModal}
        onAfterClose={(): void => setUpdateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Update Product Discount</h2>
          <div className="my-1">
            <Formik<IProductDiscountUpdateDto>
              initialValues={{
                discountValue: data?.discountValue ?? 0,
                description: data?.description ?? undefined,
                startDate: data?.startDate ? String(data?.startDate).substring(0, 16) : undefined,
                endDate: data?.endDate ? String(data?.endDate).substring(0, 16) : undefined,
                isPercent: data?.isPercent ?? false,
                isDefault: data?.isDefault ?? false,
              }}
              validationSchema={updateSchema}
              onSubmit={async (
                values: IProductDiscountUpdateDto,
                formikHelpers: FormikHelpers<IProductDiscountUpdateDto>,
              ): Promise<void> => {
                if (!data?.id) return;
                const response = await updateProductDiscountAsync(data.id, values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setUpdateModal(false);
                  setData(null);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, initialValues }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup
                    name="discountValue"
                    type="number"
                    onChange={handleChange}
                    defaultValue={initialValues.discountValue}
                    label="Discount Value"
                    placeholder="Discount Value"
                    step={0.25}
                  />
                  <FormGroup
                    name="description"
                    onChange={handleChange}
                    defaultValue={initialValues.description}
                    type="text"
                    label="Description"
                    placeholder="Description"
                  />
                  <FormGroup
                    name="startDate"
                    type="datetime-local"
                    onChange={handleChange}
                    defaultValue={initialValues.startDate}
                    label="Start Date"
                    placeholder="Start Date"
                  />
                  <FormGroup
                    name="endDate"
                    type="datetime-local"
                    onChange={handleChange}
                    defaultValue={initialValues.endDate}
                    label="End Date"
                    placeholder="End Date"
                  />
                  <FormGroup
                    name="isPercent"
                    type="checkbox"
                    onChange={handleChange}
                    defaultChecked={initialValues.isPercent}
                    label="Is Percent"
                  />
                  <FormGroup
                    name="isDefault"
                    type="checkbox"
                    onChange={handleChange}
                    defaultChecked={initialValues.isDefault}
                    label="Is Default"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setUpdateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Product Discount</h2>
          <div className="my-1">
            <Formik<IProductDiscountCreateDto>
              initialValues={{
                discountValue: 0,
                description: "",
                startDate: null,
                endDate: null,
                isPercent: false,
                isDefault: false,
                productId: null,
              }}
              validationSchema={createSchema}
              onSubmit={async (
                values: IProductDiscountCreateDto,
                formikHelpers: FormikHelpers<IProductDiscountCreateDto>,
              ): Promise<void> => {
                const response = await createProductDiscountAsync(values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setCreateModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, setFieldValue, handleChange }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-2">
                  <FormGroup
                    name="discountValue"
                    type="number"
                    onChange={handleChange}
                    label="Discount Value"
                    placeholder="Discount Value"
                    step={0.25}
                  />
                  <FormGroup
                    name="description"
                    onChange={handleChange}
                    type="text"
                    label="Description"
                    placeholder="Description"
                  />
                  <FormGroup
                    name="startDate"
                    type="datetime-local"
                    onChange={handleChange}
                    label="Start Date"
                    placeholder="Start Date"
                  />
                  <FormGroup
                    name="endDate"
                    type="datetime-local"
                    onChange={handleChange}
                    label="End Date"
                    placeholder="End Date"
                  />
                  <FormGroup name="isPercent" type="checkbox" onChange={handleChange} label="Is Percent" />
                  <FormGroup name="isDefault" type="checkbox" onChange={handleChange} label="Is Default" />
                  <div className="form-group">
                    <label className="form-label" htmlFor="productId">
                      Products
                    </label>
                    <ReactSelect
                      options={options}
                      onChange={(option): unknown => setFieldValue("productId", option?.value)}
                    />
                    <div className="form-error">
                      <ErrorMessage name="productId" component="span" className="text-danger" />
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setCreateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </section>
  );
}

export default ProductDiscountsPage;
