import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { handleValidationErrors } from "../hooks/errorhandle.hook";
import { IPromoCode, IPromoCodeCreateDto, IPromoCodeUpdateDto } from "../interfaces/promocode.interface";

export const getAllPromoCodesAsync = async (): Promise<IPromoCode[]> =>
  await axiosInstance
    .get<IPromoCode[]>("/PromoCodes/GetPromoCodes")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getPromoCodeByIdAsync = async (id: number): Promise<IPromoCode> =>
  await axiosInstance
    .get<IPromoCode>("PromoCodes/GetPromoCode/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return {} as IPromoCode;
    });

export const createPromoCodeAsync = async (
  postData: IPromoCodeCreateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> =>
  await axiosInstance
    .post("/PromoCodes/CreatePromoCode", { ...postData })
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });

export const updatePromoCodeAsync = async (
  id: number,
  putData: IPromoCodeUpdateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<boolean> =>
  await axiosInstance
    .put("PromoCodes/UpdatePromoCode", { id, ...putData })
    .then(() => {
      toast.success("Data updated successfuly");
      return true;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return false;
    });

export const togglePromoCodeAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("PromoCodes/DeletePromoCode/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => {
      console.log(err);
    });
