import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { handleValidationErrors } from "../hooks/errorhandle.hook";
import { ICategory, ICategoryCreateDto, ICategoryImage, ICategoryUpdateDto } from "../interfaces/category.interface";
import { getAntiForgeryTokenAsync } from "./auth.service";

export const getAllCategoryrAsync = async (): Promise<ICategory[]> =>
  await axiosInstance
    .get<ICategory[]>("/Categories/GetCategories")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getCategoryImagesAsync = async (id: number): Promise<ICategoryImage[]> =>
  await axiosInstance
    .get<ICategoryImage[]>("/Categories/GetCategoryImages/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getAllParentCategoriesAsync = async (): Promise<ICategory[]> =>
  await axiosInstance
    .get("Categories/GetParentCategories")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getCategoryByIdAsync = async (id: number): Promise<ICategory> =>
  await axiosInstance
    .get<ICategory>("Categories/GetCategory/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return {} as ICategory;
    });

export const createCategoryAsync = async (
  postData: ICategoryCreateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> => {
  const token = await getAntiForgeryTokenAsync();
  return await axiosInstance
    .post(
      "/Categories/CreateCategory",
      { ...postData },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-XSRF-TOKEN": token,
        },
      },
    )
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });
};

export const createCategorImageyAsync = async (
  postData: { image: File; categoryId: number },
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> => {
  const token = await getAntiForgeryTokenAsync();
  console.log(postData);
  return await axiosInstance
    .post(
      "/Categories/CreateCategoryImage",
      { ...postData },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-XSRF-TOKEN": token,
        },
      },
    )
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });
};

export const updateCategoryAsync = async (
  id: number,
  putData: ICategoryUpdateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<boolean> => {
  const token = await getAntiForgeryTokenAsync();
  return await axiosInstance
    .put(
      "Categories/UpdateCategory",
      { id, ...putData },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-XSRF-TOKEN": token,
        },
      },
    )
    .then(() => {
      toast.success("Data updated successfuly");
      return true;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return false;
    });
};

export const toggleCategoryAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("Categories/DeleteCategory/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => {
      console.log(err);
    });

export const deleteCategoryImageAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("Categories/DeleteCategoryImage/" + id)
    .then(() => {
      toast.success("Image deleted successfuly");
    })
    .catch((err) => {
      console.log(err);
    });
