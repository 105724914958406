export const handleValidationErrors = (
  errors: { [key: string]: string[] },
  setFieldError: (field: string, message: string | undefined) => void,
): void => {
  for (const field in errors) {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      setFieldError(field, errors[field].join(" "));
    }
  }
};
