import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { TOKEN_STORAGE_NAME } from "../constants/const";
import { ILoginDto, ILoginResponse, IIdentityResult } from "../types/auth.type";

export const signInAsync = async (user: ILoginDto): Promise<ILoginResponse> =>
  await axiosInstance
    .post<IIdentityResult>("/Users/Login", user)
    .then((response) => {
      if (!response.data.succeeded) toast.error(response.data.errors.join(","));

      return { succeeded: response.data.succeeded, token: response.data.token, expiryDate: response.data.expiryDate };
    })
    .catch((err) => {
      console.log(err);
      toast.error("Login failed!");
      return { succeeded: false };
    });

export const getAntiForgeryTokenAsync = async (): Promise<string | null> =>
  await axiosInstance.get("AntiForgerys/SetAntiForgeryToken").then((response) => response.data);

export const logout = (): void => {
  localStorage.removeItem(TOKEN_STORAGE_NAME);
};
