import { Navigate } from "react-router-dom";
import { useUser } from "../../auth/useUser";

function ProtectedRouteChild({
  requiredRoles,
  element,
}: {
  requiredRoles: string[];
  element: JSX.Element;
}): JSX.Element {
  const user = useUser();

  return requiredRoles.find((role) => user?.roles.includes(role)) ? element : <Navigate to="/" replace />;
}

export default ProtectedRouteChild;
