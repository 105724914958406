import axios from "axios";
import toast from "react-hot-toast";
import { HOST_API_KEY, TOKEN_STORAGE_NAME } from "../constants/const";
import { logout } from "../services/auth.service";

export const axiosInstance = axios.create({ withCredentials: true, baseURL: HOST_API_KEY });

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_STORAGE_NAME);
  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject("Network/Server error occurred");
    }

    const { status, data } = error.response;

    switch (status) {
      case 400: // Validation errors
        toast.error("Validation error");
        return Promise.reject({ ...data, isValidationError: true });
      case 401: // Unauthorized
        logout();
        window.location.href = "/";
        toast.error("Unauthorized access");
        return Promise.reject({ message: "Unauthorized access", isUnauthorized: true });
      case 403: // Forbidden
        toast.error("Forbidden access");
        return Promise.reject({ message: "Forbidden access", isForbidden: true });
      case 404: // Not Found
        toast.error("Resource not found");
        return Promise.reject({ message: "Resource not found", isNotFound: true });
      default: // Other errors
        toast.error("An error occurred");
        return Promise.reject({ message: "An error occurred", ...data });
    }
  },
);

export default axiosInstance;
