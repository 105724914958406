// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const appendFormData = (formData: FormData, data: any, parentKey = ""): void => {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        const key = `${parentKey}[${index}]`;
        appendFormData(formData, item, key);
      });
    } else {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined) {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
          appendFormData(formData, data[key], newKey);
        }
      });
    }
  } else if (data !== null && data !== undefined) {
    formData.append(parentKey, data);
  }
};
// export const appendFormData = (formData: FormData, data: any, parentKey = ""): void => {
//   if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
//     Object.keys(data).forEach((key) => {
//       if (data[key] !== null && data[key] !== undefined) {
//         appendFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
//       }
//     });
//   } else if (data !== null && data !== undefined) {
//     formData.append(parentKey, data);
//   }
// };
