import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { handleValidationErrors } from "../hooks/errorhandle.hook";
import { ICondition, IConditionCreateDto } from "../interfaces/condition.interface";

export const getAllConditionAsync = async (): Promise<ICondition[]> =>
  await axiosInstance
    .get<ICondition[]>("/Conditions/GetConditions")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getConditionByIdAsync = async (id: number): Promise<ICondition> =>
  await axiosInstance
    .get<ICondition>("Conditions/GetCondition/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return {} as ICondition;
    });

export const createConditionAsync = async (
  postData: IConditionCreateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> =>
  await axiosInstance
    .post("/Conditions/CreateCondition", { ...postData })
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });
