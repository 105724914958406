import { Field, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import SignUp from "../../assets/images/form/signup-image.jpg";
import { ReactComponent as AccountSvg } from "../../assets/svgs/account-svgrepo-com.svg";
import { ReactComponent as PasswordSvg } from "../../assets/svgs/password-form.svg";
import { useToken } from "../../auth/useToken";
import { signInAsync } from "../../services/auth.service";
import { ILoginDto } from "../../types/auth.type";
import styles from "./style.module.scss";

const authSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required!"),
  password: Yup.string().required("Password is required!").min(6, "Password is too short - should be 6 chars minimum"),
});

function Auth(): JSX.Element {
  const [, setToken] = useToken();
  const navigate = useNavigate();

  const onSubmitForm = async (values: ILoginDto): Promise<void> => {
    const result = await signInAsync(values);
    if (result.succeeded && result.token) {
      setToken(result.token);
      navigate("/dashboard/index")
    }
  };
  return (
    <section className={styles.auth}>
      <div className={styles.auth_container}>
        <div className={styles.auth_container_content}>
          <div className={styles.auth_container_content_form}>
            <h2>Login</h2>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={authSchema}
              onSubmit={onSubmitForm}
            >
              {({ handleSubmit, errors, touched }): JSX.Element => (
                <form onSubmit={handleSubmit}>
                  <div className={styles.auth_container_content_form_group}>
                    <div className={styles.auth_container_content_form_group_main}>
                      <AccountSvg className={styles.auth_container_content_form_group_main_icon} />
                      <Field name="email" type="email" placeholder="Email" />
                    </div>
                    {errors.email && touched.email ? <span>{errors.email}</span> : null}
                  </div>
                  <div className={styles.auth_container_content_form_group}>
                    <div className={styles.auth_container_content_form_group_main}>
                      <PasswordSvg className={styles.auth_container_content_form_group_main_icon} />
                      <Field name="password" type="password" placeholder="Password" />
                    </div>
                    {errors.password && touched.password ? <span>{errors.password}</span> : null}
                  </div>
                  <div className={styles.auth_container_content_form_group}>
                    <button type="submit">Submit</button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className={styles.auth_container_content_image}>
            <img src={SignUp} alt="Sign up" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Auth;
