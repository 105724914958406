import { useEffect, useRef, useState, Fragment, MouseEvent, useCallback } from "react";
import Modal from "react-modal";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logos/toggle-dark.png";
import UserProfile from "../../assets/images/users/user1.jpg";
import { ReactComponent as BellSvg } from "../../assets/svgs/bell.svg";
import { ReactComponent as ChevronRightSvg } from "../../assets/svgs/chevron-right.svg";
import { ReactComponent as ExitFullScreenSvg } from "../../assets/svgs/exit-fullscreen.svg";
import { ReactComponent as FullScreenSvg } from "../../assets/svgs/fullscreen.svg";
import { ReactComponent as HomeSvg } from "../../assets/svgs/home.svg";
import { ReactComponent as LogOutSvg } from "../../assets/svgs/logout.svg";
import { ReactComponent as UserCircleSvg } from "../../assets/svgs/user-circle.svg";
import { useUser } from "../../auth/useUser";
import { RolePages } from "../../constants/const";
import { ISlideUrl } from "../../interfaces/global.interface";
import { logout } from "../../services/auth.service";
import { switchWorkerAtWorkAsync } from "../../services/worker.service";
import { RolesEnum } from "../../types/auth.type";
import styles from "./navbar.module.scss";

const getAccessiblePages = (roles: RolesEnum[]): string[] => {
  const accessiblePagesSet = new Set<string>();

  roles.forEach((role) => {
    if (RolePages[role]) {
      RolePages[role].forEach((page) => accessiblePagesSet.add(page));
    }
  });

  return Array.from(accessiblePagesSet);
};

function filterSlideUrls(slideurls: ISlideUrl[], accessiblePages: string[]): ISlideUrl[] {
  return slideurls
    .map((slideurl) => {
      return {
        ...slideurl,
        slides: slideurl.slides
          .map((slide) => {
            return {
              ...slide,
              children: slide.children.filter((child) => accessiblePages.includes(child.to)),
            };
          })
          .filter((slide) => slide.children.length > 0),
      };
    })
    .filter((slideurl) => slideurl.slides.length > 0);
}

const slideUrls: ISlideUrl[] = [
  {
    name: "main",
    slides: [
      {
        name: "Dashboards",
        icon: <HomeSvg className={styles.aside_main_content_nav_menu_slide_btn_icon} />,
        children: [
          { name: "Home", to: "index" },
          { name: "Delivery Types", to: "deliverytypes" },
          { name: "Manufacturers", to: "manufacturers" },
          { name: "Categories", to: "categories" },
          { name: "Products", to: "products" },
          { name: "Product Discounts", to: "productdiscounts" },
          { name: "Redeem Codes", to: "redeemcodes" },
          { name: "Promo Codes", to: "promocodes" },
          { name: "Workers", to: "workers" },
          { name: "Operation Statuses", to: "operationstatuses" },
          { name: "Conditions", to: "conditions" },
          { name: "Payment Types", to: "paymenttypes" },
          { name: "Campaigns", to: "campaigns" },
          { name: "Streamers", to: "streamers" },
        ],
      },
      {
        name: "Accounts",
        icon: <HomeSvg className={styles.aside_main_content_nav_menu_slide_btn_icon} />,
        children: [
          { name: "Roles", to: "roles" },
          { name: "Users", to: "users" },
        ],
      },
      {
        name: "Test",
        icon: <HomeSvg className={styles.aside_main_content_nav_menu_slide_btn_icon} />,
        children: [{ name: "Orders", to: "orders" }],
      },
    ],
  },
];

const toggleFullScreen = (): Promise<void> =>
  !document.fullscreenElement ? document.body.requestFullscreen() : document.exitFullscreen();

function Navbar(): JSX.Element {
  const user = useUser();
  const accessiblePages = getAccessiblePages(user?.roles as [RolesEnum]);
  const filteredSlideUrls = filterSlideUrls(slideUrls, accessiblePages);

  const [userModalIsOpen, setUserIsOpen] = useState(false);
  const [userModalPosition, setUserModalPosition] = useState({ top: 0, right: 0 });
  const [isAtWork, setIsAtWork] = useState(user?.isAtWork);
  const slides = useRef<(HTMLLIElement | null)[]>([]);
  const aside = useRef<HTMLElement>(null);
  const location = useLocation();

  const toggleSlide = (e: HTMLLIElement): void => {
    const slidesContents = slides.current.filter((slide) => slide != e);
    slidesContents.forEach((slide) => slide?.classList.remove(styles.slide_open));
    e.classList.toggle(styles.slide_open);
  };

  const toggleBurger = (e: HTMLButtonElement): void => {
    e.classList.toggle(styles.close_burger);
    aside.current?.classList.toggle(styles.mini_aside);
  };

  const openUserModal = (event: MouseEvent<HTMLButtonElement>): void => {
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setUserModalPosition({ top: buttonRect.bottom, right: buttonRect.right });
    setUserIsOpen((state) => !state);
  };

  const handleWprkStatus = useCallback(async () => {
    setIsAtWork(await switchWorkerAtWorkAsync());
  }, []);

  useEffect(() => {
    const activeSlide = slides.current.find((slide) => slide?.querySelector("ul li a." + styles.item_active) != null);
    if (!activeSlide?.classList.contains(styles.slide_open)) activeSlide?.classList.add(styles.slide_open);
  }, [location]);

  return (
    <>
      <header className={styles.header}>
        <div className={styles.header_container}>
          <div className={styles.header_container_left}>
            <button
              onClick={(e): void => toggleBurger(e.currentTarget)}
              className={styles.header_container_left_burger}
            >
              <span className={styles.header_container_left_burger_span}></span>
            </button>
          </div>

          <div className={styles.header_container_right}>
            <div>
              {user?.workerId && (
                <button
                  className="btn btn-sm"
                  style={{ backgroundColor: isAtWork ? "green" : "orange" }}
                  onClick={async (): Promise<void> => await handleWprkStatus()}
                >
                  {isAtWork ? "At Work" : "Break"}
                </button>
              )}
            </div>

            <div className={styles.header_container_right_notification}>
              <button className={styles.header_container_right_notification_btn}>
                <BellSvg />
                <span>5</span>
              </button>
            </div>

            <div className={styles.header_container_right_fullscreen}>
              <button className={styles.header_container_right_fullscreen_btn}>
                <FullScreenSvg
                  onClick={toggleFullScreen}
                  className={styles.header_container_right_fullscreen_btn_open}
                />
                <ExitFullScreenSvg
                  onClick={toggleFullScreen}
                  className={styles.header_container_right_fullscreen_btn_close}
                />
              </button>
            </div>

            <div className={styles.header_container_right_user}>
              <button onClick={openUserModal} className={styles.header_container_right_user_btn}>
                <div className={styles.header_container_right_user_btn_img}>
                  <img src={UserProfile} alt="profile" />
                </div>
                <div className={styles.header_container_right_user_btn_text}>
                  <p>{user?.name}</p>
                  <span>
                    {user?.roles.map((x) => (
                      <Fragment key={x}>
                        {x} <br />
                      </Fragment>
                    ))}
                  </span>
                </div>
              </button>
              <Modal
                isOpen={userModalIsOpen}
                className={styles.header_container_right_user_modal}
                onRequestClose={(): void => setUserIsOpen(false)}
                contentLabel="User Modal"
                style={{
                  content: {
                    top: userModalPosition.top,
                    left: userModalPosition.right,
                    transform: "translate(-100%,0%)",
                  },
                }}
              >
                <ul className={styles.header_container_right_user_dropdown}>
                  <li className={styles.header_container_right_user_dropdown_item}>
                    <a href="#">
                      <UserCircleSvg />
                      Profile
                    </a>
                  </li>
                  <li className={styles.header_container_right_user_dropdown_item}>
                    <Link to="/" replace={true} onClick={logout}>
                      <LogOutSvg />
                      Log Out
                    </Link>
                  </li>
                </ul>
              </Modal>
            </div>
          </div>
        </div>
      </header>

      <aside ref={aside} className={styles.aside}>
        <div className={styles.aside_header}>
          <Link to="index" className={styles.aside_header_logo}>
            <span>CosmoChest</span>
            <img src={Logo} alt="logo" />
          </Link>
        </div>

        <div className={styles.aside_main}>
          <div className={styles.aside_main_content}>
            <nav className={styles.aside_main_content_nav}>
              <ul className={styles.aside_main_content_nav_menu}>
                {filteredSlideUrls.map((category, key) => (
                  <Fragment key={key}>
                    <li className={styles.aside_main_content_nav_menu_category}>
                      <span>{category.name}</span>
                    </li>

                    {category.slides.map((slide, key) => (
                      <li
                        key={key}
                        ref={(e): null | HTMLLIElement => (slides.current[key] = e)}
                        onClick={(e): void => toggleSlide(e.currentTarget)}
                        className={styles.aside_main_content_nav_menu_slide}
                      >
                        <button className={styles.aside_main_content_nav_menu_slide_btn}>
                          {slide.icon}
                          <span>{slide.name}</span>
                          <ChevronRightSvg className={styles.aside_main_content_nav_menu_slide_btn_angle} />
                        </button>

                        <ul className={styles.aside_main_content_nav_menu_slide_list}>
                          {slide.children.map((child, key) => (
                            <li key={key} className={styles.aside_main_content_nav_menu_slide_list_item}>
                              <NavLink
                                to={child.to}
                                className={({ isActive }): string => (isActive ? styles.item_active : undefined)}
                              >
                                {child.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </Fragment>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </aside>
    </>
  );
}

export default Navbar;
