import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import * as Yup from "yup";
import FormGroup from "../../components/formGroup";
import { ICondition, IConditionCreateDto } from "../../interfaces/condition.interface";
import { createConditionAsync, getAllConditionAsync } from "../../services/condition.service";

const createSchema = Yup.object().shape({
  levelThreshold: Yup.number().moreThan(-1).required("Required!"),
  emailConfirmedXP: Yup.number().moreThan(-1).required("Required!"),
  levelIncreasePercentage: Yup.number().moreThan(-1).required("Required!"),
  rankIncreasePercentage: Yup.number().moreThan(-1).required("Required!"),
});

function ConditionsPage(): JSX.Element {
  const [datas, setDatas] = useState<ICondition[]>([]);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllConditionAsync());
  }, []);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const [colDefs] = useState<ColDef<ICondition>[]>([
    { headerName: "Id", field: "id" as keyof ICondition },
    { headerName: "Created At", field: "createdAt" as keyof ICondition },
    { headerName: "Level Threshold", field: "levelThreshold" as keyof ICondition },
    { headerName: "Email Confirmed XP", field: "emailConfirmedXP" as keyof ICondition },
    { headerName: "Level Increase Percentage", field: "levelIncreasePercentage" as keyof ICondition },
    { headerName: "Rank Increase Percentage", field: "rankIncreasePercentage" as keyof ICondition },
  ]);
  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Conditions</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Conditions</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={(): void => setCreateModal(true)}>
              + Create Condition
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<ICondition>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Condition</h2>
          <div className="my-1">
            <Formik<IConditionCreateDto>
              initialValues={{
                levelThreshold: 0,
                emailConfirmedXP: 0,
                levelIncreasePercentage: 0,
                rankIncreasePercentage: 0,
              }}
              validationSchema={createSchema}
              onSubmit={async (
                values: IConditionCreateDto,
                formikHelpers: FormikHelpers<IConditionCreateDto>,
              ): Promise<void> => {
                const response = await createConditionAsync(values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setCreateModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-2">
                  <FormGroup
                    name="levelThreshold"
                    type="number"
                    onChange={handleChange}
                    label="Level Threshold"
                    placeholder="Level Threshold"
                    step={0.25}
                  />
                  <FormGroup
                    name="emailConfirmedXP"
                    type="number"
                    onChange={handleChange}
                    label="Email Confirmed XP"
                    placeholder="Email Confirmed X"
                    step={0.25}
                  />
                  <FormGroup
                    name="levelIncreasePercentage"
                    type="number"
                    onChange={handleChange}
                    label="Level Increase Percentage"
                    placeholder="Level Increase Percentage"
                    step={0.25}
                  />
                  <FormGroup
                    name="rankIncreasePercentage"
                    type="number"
                    onChange={handleChange}
                    label="Rank Increase Percentage"
                    placeholder="Rank Increase Percentage"
                    step={0.25}
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setCreateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </section>
  );
}

export default ConditionsPage;
