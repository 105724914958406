import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { handleValidationErrors } from "../hooks/errorhandle.hook";
import { IDeliveryType, IDeliveryTypeCreateDto, IDeliveryTypeUpdateDto } from "../interfaces/deliverytype.interface";

export const getAllDeliveryTypeAsync = async (): Promise<IDeliveryType[]> =>
  await axiosInstance
    .get<IDeliveryType[]>("/DeliveryTypes/GetDeliveryTypes")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getDeliveryTypeByIdAsync = async (id: number): Promise<IDeliveryType> =>
  await axiosInstance
    .get<IDeliveryType>("DeliveryTypes/GetDeliveryType/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return {} as IDeliveryType;
    });

export const createDeliveryTypeAsync = async (
  postData: IDeliveryTypeCreateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> =>
  await axiosInstance
    .post("/DeliveryTypes/CreateDeliveryType", { ...postData })
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });

export const updateDeliveryTypeAsync = async (
  id: number,
  putData: IDeliveryTypeUpdateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<boolean> =>
  await axiosInstance
    .put("DeliveryTypes/UpdateDeliveryType", { id, ...putData })
    .then(() => {
      toast.success("Data updated successfuly");
      return true;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return false;
    });

export const toggleDeliveryTypeAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("DeliveryTypes/DeleteDeliveryType/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => {
      console.log(err);
    });
