import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { IOperationStatus } from "../interfaces/operationstatus.interface";

export const getAllOperationStatusAsync = async (): Promise<IOperationStatus[]> =>
  await axiosInstance
    .get<IOperationStatus[]>("/OperationStatuses/GetOperationStatuses")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const toggleOperationStatusAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("OperationStatuses/DeleteOperationStatus/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => {
      console.log(err);
    });
