import { useEffect, useState } from "react";
import { IIdentityUser, IIdentityUserDto } from "../types/auth.type";
import { useToken } from "./useToken";

export const useUser = (): IIdentityUser | null => {
  const [token] = useToken();

  const getPayloadFromToken = (token: string): IIdentityUser | null => {
    try {
      const endcodedPayload = token.split(".")[1];
      const authUser: IIdentityUserDto = JSON.parse(atob(endcodedPayload));
      const identityUser: IIdentityUser = {
        sub: authUser.sub,
        name: authUser.name,
        email: authUser.email,
        workerId: authUser.workerId,
        isAtWork: authUser.isAtWork,
        roles: (Array.isArray(authUser.role) ? authUser.role : [authUser.role]) || [],
      };
      return identityUser;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const [user, setUser] = useState(() => {
    if (!token) return null;
    return getPayloadFromToken(token);
  });

  useEffect(() => {
    if (!token) setUser(null);
    else setUser(getPayloadFromToken(token));
  }, [token]);

  return user;
};
