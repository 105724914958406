import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import Select from "react-select";
import * as Yup from "yup";
import ActionsCopmonent from "../../components/actions";
import FormGroup from "../../components/formGroup";
import { getImageSrc, handleFileChange } from "../../hooks/file.hook";
import {
  ICategory,
  ICategoryCreateDto,
  ICategoryCreateImageDto,
  ICategoryImage,
  ICategoryUpdateDto,
} from "../../interfaces/category.interface";
import { IOption } from "../../interfaces/global.interface";
import {
  createCategorImageyAsync,
  createCategoryAsync,
  deleteCategoryImageAsync,
  getAllCategoryrAsync,
  getAllParentCategoriesAsync,
  getCategoryByIdAsync,
  getCategoryImagesAsync,
  toggleCategoryAsync,
  updateCategoryAsync,
} from "../../services/category.service";

const createSchema = Yup.object().shape(
  {
    title: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
    metaTitle: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
    slug: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
    description: Yup.string().min(20, "Too Short!").max(1000, "Too Long!").notRequired(),
    helpDescription: Yup.string().min(20, "Too Short!").max(1000, "Too Long!").notRequired(),
    isPopular: Yup.boolean().required(),
    isMain: Yup.boolean().required(),
    sortOrder: Yup.number().min(0).required(),
    parentCategoryId: Yup.number().when("isMain", ([isMain], schema) => {
      if (isMain) return schema.notRequired();
      else return schema.required("Required!");
    }),
    image: Yup.mixed<File>().when(["isMain", "image"], ([isMain, image], schema) => {
      if (isMain)
        return schema
          .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024 * 3) // 3MB
          .test(
            "fileFormat",
            "Unsupported Format",
            (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
          )
          .required("Required!");
      else if (image instanceof File)
        return schema
          .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024 * 3) // 3MB
          .test(
            "fileFormat",
            "Unsupported Format",
            (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
          )
          .notRequired();
      else return schema.notRequired();
    }),
  },
  [["image", "image"]],
);

const updateSchema = Yup.object().shape(
  {
    title: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
    metaTitle: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
    slug: Yup.string().min(3, "Too Short!").max(50, "Too Long!").required("Required!"),
    description: Yup.string().min(20, "Too Short!").max(1000, "Too Long!").notRequired(),
    helpDescription: Yup.string().min(20, "Too Short!").max(1000, "Too Long!").notRequired(),
    isPopular: Yup.boolean().required(),
    sortOrder: Yup.number().min(0).required(),
    parentCategoryId: Yup.number().when("isMain", ([isMain], schema) => {
      if (isMain) return schema.notRequired();
      else return schema.required("Required!");
    }),
    image: Yup.mixed<File>().when("image", ([image], schema) => {
      if (image instanceof File)
        return schema
          .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024 * 3) // 3MB
          .test(
            "fileFormat",
            "Unsupported Format",
            (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
          )
          .required("Required!");
      else return schema.notRequired();
    }),
  },
  [["image", "image"]],
);

const createCategoryImageSchema = Yup.object().shape({
  image: Yup.mixed<File>().when((files, schema) =>
    schema
      .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024 * 4) // 4MB
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
      ),
  ),
});

function CategoriesPage(): JSX.Element {
  const [datas, setDatas] = useState<ICategory[]>([]);
  const [data, setData] = useState<ICategory | null>(null);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [categoryImages, setCategoryImages] = useState<ICategoryImage[]>([]);
  const [options, setOptions] = useState<IOption[]>([]);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [imagesModal, setImagesModal] = useState(false);
  const [elementVisible, setElementVisible] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllCategoryrAsync());
  }, []);

  const getParentCategories = useCallback(async () => {
    const parentCategories = await getAllParentCategoriesAsync();
    setOptions(
      parentCategories.map((category) => {
        return { label: category.title, value: category.id };
      }),
    );
  }, []);

  const getCategoryImages = useCallback(async (id: number): Promise<void> => {
    setCategoryImages(await getCategoryImagesAsync(id));
  }, []);

  useEffect(() => {
    getAllData();
    getParentCategories();
  }, [getAllData, getParentCategories]);

  const handleDelete = useCallback(async (id: number) => {
    await toggleCategoryAsync(id);
    await getAllData();
  }, []);

  const handleRemoveImage = useCallback(async (id: number) => {
    await deleteCategoryImageAsync(id);
    await getCategoryImages(id);
  }, []);

  const handleEdit = useCallback(async (id: number) => {
    setData(await getCategoryByIdAsync(id));
    setUpdateModal(true);
  }, []);

  const onStock = useCallback(async (id: number) => {
    setCategoryId(id);
    await getCategoryImages(id);
    setImagesModal(true);
  }, []);

  const [colDefs] = useState<ColDef<ICategory>[]>([
    { headerName: "Id", field: "id" as keyof ICategory },
    { headerName: "Title", field: "title" as keyof ICategory },
    { headerName: "MetaTitle", field: "metaTitle" as keyof ICategory },
    { headerName: "Slug", field: "slug" as keyof ICategory },
    { headerName: "Sort Order", field: "sortOrder" as keyof ICategory },
    { headerName: "Description", field: "description" as keyof ICategory },
    { headerName: "Help Description", field: "helpDescription" as keyof ICategory },
    { headerName: "Is Main", field: "isMain" as keyof ICategory },
    { headerName: "Is Popular", field: "isPopular" as keyof ICategory },
    { headerName: "Is Disabled", field: "isDisabled" as keyof ICategory },
    {
      headerName: "Image",
      field: "image" as keyof ICategory,
      cellRenderer: (params: CustomCellRendererProps) =>
        params.value && (
          <img className="gridjs-search-img" src={getImageSrc("categories", params.value)} alt="category" />
        ),
    },
    {
      headerName: "Actions",
      field: "id" as keyof ICategory,
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionsCopmonent id={params.value} onUpdate={handleEdit} onDelete={handleDelete} onStock={onStock} />
      ),
    },
  ]);

  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Categories</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Categories</div>
          <div className="d-flex flex-wrap gap-2">
            <button
              className="btn btn-primary"
              onClick={(): void => {
                setElementVisible(false), setCreateModal(true);
              }}
            >
              + Create Category
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<ICategory>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={updateModal}
        onAfterClose={(): void => setUpdateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Update Category</h2>
          <div className="my-1">
            <Formik<ICategoryUpdateDto>
              initialValues={{
                title: data?.title ?? "",
                metaTitle: data?.metaTitle ?? "",
                slug: data?.slug ?? "",
                description: data?.description ?? "",
                helpDescription: data?.helpDescription ?? "",
                isPopular: data?.isPopular ?? false,
                isMain: data?.isMain ?? false,
                sortOrder: data?.sortOrder ?? 0,
                parentCategoryId: data?.parentCategoryId ?? null,
                image: null,
              }}
              validationSchema={updateSchema}
              onSubmit={async (
                values: ICategoryUpdateDto,
                formikHelpers: FormikHelpers<ICategoryUpdateDto>,
              ): Promise<void> => {
                if (!data?.id) return;
                const response = await updateCategoryAsync(data.id, values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setUpdateModal(false);
                  setData(null);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, setFieldValue, initialValues }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup
                    name="title"
                    type="text"
                    defaultValue={initialValues.title}
                    onChange={handleChange}
                    label="Title"
                    placeholder="Title"
                  />
                  <FormGroup
                    name="metaTitle"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.metaTitle}
                    label="Meta Title"
                    placeholder="Meta Title"
                  />
                  <FormGroup
                    name="slug"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.slug}
                    label="Slug"
                    placeholder="Slug"
                  />
                  <FormGroup
                    name="description"
                    onChange={handleChange}
                    defaultValue={initialValues.description}
                    type="text"
                    label="Description"
                    placeholder="Description"
                  />
                  <FormGroup
                    name="helpDescription"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.helpDescription}
                    label="Help Description"
                    placeholder="Help Description"
                  />
                  <FormGroup
                    name="isMain"
                    type="checkbox"
                    onChange={handleChange}
                    defaultChecked={initialValues.isMain}
                    label="Is Main"
                    placeholder="Is Main"
                    disabled={true}
                  />
                  <FormGroup
                    name="isPopular"
                    type="checkbox"
                    onChange={handleChange}
                    defaultChecked={initialValues.isPopular}
                    label="Is Popular"
                    placeholder="Is Popular"
                  />
                  <FormGroup
                    name="sortOrder"
                    type="number"
                    onChange={handleChange}
                    defaultValue={initialValues.sortOrder}
                    label="Sort Order"
                    placeholder="Sort Order"
                  />
                  <div style={{ display: data?.isMain ? "none" : "block" }} className="form-group">
                    <label className="form-label" htmlFor="parentCategoryId">
                      Parent Category
                    </label>
                    <Select
                      options={options}
                      defaultValue={options.find((option) => option.value === initialValues.parentCategoryId)}
                      onChange={(option): unknown => setFieldValue("parentCategoryId", option?.value)}
                    />
                    <div className="form-error">
                      <ErrorMessage name="parentCategoryId" component="span" className="text-danger" />
                    </div>
                  </div>
                  <FormGroup
                    name="image"
                    label="Image"
                    type="file"
                    onChange={(event): void => handleFileChange(event, setFieldValue)}
                    accept="image/png, image/jpeg"
                    placeholder="Image"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setUpdateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Category</h2>
          <div className="my-1">
            <Formik<ICategoryCreateDto>
              initialValues={{
                title: "",
                metaTitle: "",
                slug: "",
                description: "",
                helpDescription: "",
                isPopular: false,
                isMain: false,
                sortOrder: 0,
                parentCategoryId: null,
                image: null,
              }}
              validationSchema={createSchema}
              onSubmit={async (
                values: ICategoryCreateDto,
                formikHelpers: FormikHelpers<ICategoryCreateDto>,
              ): Promise<void> => {
                const response = await createCategoryAsync(values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setCreateModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, setFieldValue, handleChange }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-2">
                  <FormGroup name="title" type="text" onChange={handleChange} label="Title" placeholder="Title" />
                  <FormGroup
                    name="metaTitle"
                    type="text"
                    onChange={handleChange}
                    label="Meta Title"
                    placeholder="Meta Title"
                  />
                  <FormGroup name="slug" type="text" onChange={handleChange} label="Slug" placeholder="Slug" />
                  <FormGroup
                    name="description"
                    onChange={handleChange}
                    type="text"
                    label="Description"
                    placeholder="Description"
                  />
                  <FormGroup
                    name="helpDescription"
                    type="text"
                    onChange={handleChange}
                    label="Help Description"
                    placeholder="Help Description"
                  />
                  <FormGroup
                    name="isPopular"
                    type="checkbox"
                    onChange={handleChange}
                    label="Is Popular"
                    placeholder="Is Popular"
                  />
                  <FormGroup
                    name="isMain"
                    type="checkbox"
                    onChange={(e): void => {
                      handleChange(e);
                      setFieldValue("isMain", e.currentTarget.checked), setElementVisible(!elementVisible);
                    }}
                    label="Is Main"
                    placeholder="Is Main"
                  />
                  <FormGroup
                    name="sortOrder"
                    type="number"
                    onChange={handleChange}
                    label="Sort Order"
                    placeholder="Sort Order"
                  />
                  <FormGroup
                    name="image"
                    label="Image"
                    type="file"
                    onChange={(event): void => handleFileChange(event, setFieldValue)}
                    accept="image/png, image/jpeg"
                    placeholder="Image"
                  />
                  <div style={{ display: elementVisible ? "none" : "block" }} className="form-group">
                    <label className="form-label" htmlFor="parentCategoryId">
                      Main Categories
                    </label>
                    <Select
                      options={options}
                      onChange={(option): unknown => setFieldValue("parentCategoryId", option?.value)}
                    />
                    <div className="form-error">
                      <ErrorMessage name="parentCategoryId" component="span" className="text-danger" />
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setCreateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={imagesModal}
        onAfterClose={(): void => setImagesModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <div className="d-flex gap-3">
            <h2>Category Images</h2>
            <button className="btn btn-success" onClick={(): void => setImageModal(true)}>
              Add New Image
            </button>
          </div>
          <div className="my-1">
            <div className="d-flex gap-2">
              {categoryImages.map((categoryImage) => (
                <div key={categoryImage.id} className="d-flex flex-column">
                  <img
                    className="mb-3"
                    style={{ width: "119px", height: "88px" }}
                    src={getImageSrc("categories", categoryImage.image)}
                    alt="category"
                  />
                  <button
                    className="btn btn-danger-light"
                    onClick={async (): Promise<void> => await handleRemoveImage(categoryImage.id)}
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex flex-row-reverse gap-2">
            <button type="button" className="btn btn-light" onClick={(): void => setImagesModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={imageModal}
        onAfterClose={(): void => setImageModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Image</h2>
          <div className="my-1">
            <Formik<ICategoryCreateImageDto>
              initialValues={{
                image: null,
              }}
              validationSchema={createCategoryImageSchema}
              onSubmit={async (
                values: ICategoryCreateImageDto,
                formikHelpers: FormikHelpers<ICategoryCreateImageDto>,
              ): Promise<void> => {
                if (!values.image || !categoryId) return;
                const data = { image: values.image, categoryId };
                const response = await createCategorImageyAsync(data, formikHelpers.setFieldError);

                if (response) {
                  await getCategoryImages(categoryId);
                  setImageModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, setFieldValue }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-2">
                  <FormGroup
                    name="image"
                    label="Image"
                    type="file"
                    onChange={(event): void => handleFileChange(event, setFieldValue)}
                    accept="image/png, image/jpeg"
                    placeholder="Image"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setImageModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </section>
  );
}

export default CategoriesPage;
