import { RolesEnum } from "../types/auth.type";

export const HOST_API_KEY = "https://api.cosmo-chest.com/api";
export const BASE_URL = "https://api.cosmo-chest.com/";
// export const HOST_API_KEY = "https://localhost:5000/api";
// export const BASE_URL = "https://localhost:5000/";

export const TOKEN_STORAGE_NAME = "cosmochest-jwttoken";
export const XSRF_TOKEN_NAME = "X-XSRF-TOKEN";

export const RolePages = {
  [RolesEnum.ADMIN]: [
    "index",
    "users",
    "products",
    "categories",
    "orders",
    "deliverytypes",
    "manufacturers",
    "promocodes",
    "operationstatuses",
    "conditions",
    "roles",
    "paymenttypes",
    "campaigns",
    "streamers",
    "redeemcodes",
    "workers",
    "productdiscounts",
    "createredeemcode/:productId",
  ],
  [RolesEnum.MODERATOR]: [
    "index",
    "users",
    "products",
    "categories",
    "campaigns",
    "streamers",
    "redeemcodes",
    "workers",
    "productdiscounts",
    "createredeemcode/:productId",
  ],
};
