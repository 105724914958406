import ReactDOM from "react-dom/client";
import ReactModal from "react-modal";
import App from "./App";
import "./fonts.css";
import "./index.css";
import "./style.scss";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
ReactModal.setAppElement(document.getElementById("root") as HTMLElement);

root.render(<App />);
