import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import Select from "react-select";
import * as Yup from "yup";
import ActionsCopmonent from "../../components/actions";
import FormGroup from "../../components/formGroup";
import { getImageSrc, handleFileChange } from "../../hooks/file.hook";
import { IOption } from "../../interfaces/global.interface";
import { IRole } from "../../interfaces/role.interface";
import { IUser, IUserCreateDto, IUserUpdateDto } from "../../interfaces/user.interface";
import { getAllRolesAsync } from "../../services/role.service";
import {
  createUserAsync,
  getAllUsersAsync,
  getUserByIdAsync,
  toggleUserAsync,
  updateUserAsync,
} from "../../services/user.service";

const createSchema = Yup.object().shape({
  firstName: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  lastName: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  email: Yup.string().email("Invalid email").required("Email is required!"),
  password: Yup.string().required("Password is required!").min(6, "Password is too short - should be 6 chars minimum"),
  isFemale: Yup.boolean(),
  dateOfBirth: Yup.date().notRequired(),
  roleIds: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number().required("Role is required"),
        label: Yup.string().required(),
      }),
    )
    .min(1, "At least one role is required")
    .required(),
});

const updateSchema = Yup.object().shape({
  firstName: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  lastName: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  email: Yup.string().email("Invalid email").required("Email is required!"),
  isFemale: Yup.boolean(),
  dateOfBirth: Yup.date().notRequired(),
  image: Yup.mixed<File>().when((files, schema) => {
    if (files.some((obj) => obj !== null && files[0] instanceof File))
      return schema
        .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024) // 1MB
        .test(
          "fileFormat",
          "Unsupported Format",
          (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
        );
    else return schema.notRequired();
  }),
  roleIds: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number().required("Role is required"),
        label: Yup.string().required(),
      }),
    )
    .min(1, "At least one role is required")
    .required(),
});

function UsersPage(): JSX.Element {
  const [datas, setDatas] = useState<IUser[]>([]);
  const [options, setOptions] = useState<IOption[]>([]);
  const [data, setData] = useState<IUser | null>();
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllUsersAsync());
  }, []);

  const getAllRoles = useCallback(async (): Promise<void> => {
    const roles = await getAllRolesAsync();
    setOptions(
      roles.map((role) => {
        return { value: role.id, label: role.name };
      }),
    );
  }, []);

  useEffect(() => {
    getAllData();
    getAllRoles();
  }, [getAllData, getAllRoles]);

  const handleDelete = useCallback(async (id: number) => {
    await toggleUserAsync(id);
    await getAllData();
  }, []);

  const handleEdit = useCallback(async (id: number) => {
    setData(await getUserByIdAsync(id));
    setUpdateModal(true);
  }, []);

  const [colDefs] = useState<ColDef<IUser>[]>([
    { headerName: "Id", field: "id" as keyof IUser },
    { headerName: "First Name", field: "firstName" as keyof IUser },
    { headerName: "Last Name", field: "lastName" as keyof IUser },
    { headerName: "Email", field: "email" as keyof IUser },
    { headerName: "Date Of Birth", field: "dateOfBirth" as keyof IUser },
    { headerName: "Created At", field: "createdAt" as keyof IUser },
    { headerName: "Is Female", field: "isFemale" as keyof IUser },
    { headerName: "Is Disabled", field: "isDisabled" as keyof IUser },
    {
      headerName: "Roles",
      field: "userRoles" as keyof IUser,
      valueFormatter: (p: { value: IRole[] }) => p.value.map((role) => role.name).join("\r"),
    },
    {
      headerName: "Image",
      field: "image" as keyof IUser,
      cellRenderer: (params: CustomCellRendererProps) =>
        params.value && <img className="gridjs-search-img" src={getImageSrc("users", params.value)} alt="user" />,
    },
    {
      headerName: "Actions",
      field: "id" as keyof IUser,
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionsCopmonent id={params.value} onUpdate={handleEdit} onDelete={handleDelete} />
      ),
    },
  ]);

  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Users</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Delivery Types</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={(): void => setCreateModal(true)}>
              + Create User
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<IUser>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={updateModal}
        onAfterClose={(): void => setUpdateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Update User</h2>
          <div className="my-1">
            <Formik<IUserUpdateDto>
              initialValues={{
                firstName: data?.firstName ?? "",
                lastName: data?.lastName ?? "",
                email: data?.email ?? "",
                isFemale: data?.isFemale ?? false,
                dateOfBirth: data?.dateOfBirth ?? null,
                image: null,
                roleIds: options.filter(({ value }) => data?.userRoles.some((userRole) => userRole.id == value)) ?? [],
              }}
              validationSchema={updateSchema}
              onSubmit={async (values: IUserUpdateDto, formikHelpers: FormikHelpers<IUserUpdateDto>): Promise<void> => {
                if (!data?.id) return;
                const response = await updateUserAsync(data.id, values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setUpdateModal(false);
                  setData(null);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, setFieldValue, handleChange, values }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup
                    name="firstName"
                    type="text"
                    onChange={handleChange}
                    label="First Name"
                    placeholder="First Name"
                  />
                  <FormGroup
                    name="lastName"
                    type="text"
                    onChange={handleChange}
                    label="Last Name"
                    placeholder="Last Name"
                  />
                  <FormGroup name="email" label="Email" onChange={handleChange} type="email" placeholder="Email" />
                  <FormGroup
                    name="isFemale"
                    type="checkbox"
                    onChange={handleChange}
                    label="Is Female"
                    placeholder="Is Female"
                  />
                  <FormGroup
                    name="dateOfBirth"
                    type="date"
                    onChange={handleChange}
                    label="Date Of Birth"
                    placeholder="Date Of Birth"
                  />
                  <FormGroup
                    name="image"
                    type="file"
                    onChange={(event): void => handleFileChange(event, setFieldValue)}
                    label="Image"
                    accept="image/png, image/jpeg"
                    placeholder="Image"
                  />
                  <div>
                    <label className="form-label" htmlFor="roleIds">
                      Roles
                    </label>
                    <Select
                      options={options}
                      value={values.roleIds}
                      onChange={(option): unknown => setFieldValue("roleIds", option)}
                      isMulti
                    />
                    <ErrorMessage name="roleIds" component="span" className="text-danger" />
                  </div>
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setUpdateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <h2>Create New User</h2>
        <div className="my-1">
          <Formik<IUserCreateDto>
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              isFemale: false,
              dateOfBirth: null,
              roleIds: [],
            }}
            validationSchema={createSchema}
            onSubmit={async (values: IUserCreateDto, formikHelpers: FormikHelpers<IUserCreateDto>): Promise<void> => {
              const response = await createUserAsync(values, formikHelpers.setFieldError);

              if (response) {
                await getAllData();
                setCreateModal(false);
                formikHelpers.resetForm();
              }
            }}
          >
            {({ handleSubmit, isSubmitting, values, setFieldValue, handleChange }): JSX.Element => (
              <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                <FormGroup
                  name="firstName"
                  type="text"
                  onChange={handleChange}
                  label="First Name"
                  placeholder="First Name"
                />
                <FormGroup
                  name="lastName"
                  type="text"
                  onChange={handleChange}
                  label="Last Name"
                  placeholder="Last Name"
                />
                <FormGroup name="email" label="Email" onChange={handleChange} type="email" placeholder="Email" />
                <FormGroup
                  name="password"
                  type="password"
                  onChange={handleChange}
                  label="Password"
                  placeholder="Password"
                />
                <FormGroup
                  name="isFemale"
                  type="checkbox"
                  onChange={handleChange}
                  label="Is Female"
                  placeholder="Is Female"
                />
                <FormGroup
                  name="dateOfBirth"
                  type="date"
                  onChange={handleChange}
                  label="Date Of Birth"
                  placeholder="Date Of Birth"
                />
                <div>
                  <label className="form-label" htmlFor="roleIds">
                    Roles
                  </label>
                  <Select
                    options={options}
                    value={values.roleIds}
                    onChange={(option): unknown => setFieldValue("roleIds", option)}
                    isMulti
                  />
                  <ErrorMessage name="roleIds" component="span" className="text-danger" />
                </div>
                <div className="d-flex flex-row-reverse gap-2">
                  <button
                    type="button"
                    className="btn btn-light"
                    disabled={isSubmitting}
                    onClick={(): void => setCreateModal(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Create
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ReactModal>
    </section>
  );
}

export default UsersPage;
