import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { handleValidationErrors } from "../hooks/errorhandle.hook";
import { IPaymentType, IPaymentTypeCreateDto, IPaymentTypeUpdateDto } from "../interfaces/paymenttype.interface";
import { getAntiForgeryTokenAsync } from "./auth.service";

export const getAllPaymentTypesAsync = async (): Promise<IPaymentType[]> =>
  await axiosInstance
    .get<IPaymentType[]>("/PaymentTypes/GetPaymentTypes")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getPaymentTypeByIdAsync = async (id: number): Promise<IPaymentType> =>
  await axiosInstance
    .get<IPaymentType>("PaymentTypes/GetPaymentType/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return {} as IPaymentType;
    });

export const createPaymentTypeAsync = async (
  postData: IPaymentTypeCreateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> => {
  const token = await getAntiForgeryTokenAsync();
  return await axiosInstance
    .post(
      "/PaymentTypes/CreatePaymentType",
      { ...postData },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-XSRF-TOKEN": token,
        },
      },
    )
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });
};

export const updatePaymentTypeAsync = async (
  id: number,
  putData: IPaymentTypeUpdateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<boolean> => {
  const token = await getAntiForgeryTokenAsync();
  return await axiosInstance
    .put(
      "PaymentTypes/UpdatePaymentType",
      { id, ...putData },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-XSRF-TOKEN": token,
        },
      },
    )
    .then(() => {
      toast.success("Data updated successfuly");
      return true;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return false;
    });
};

export const togglePaymentTypeAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("PaymentTypes/DeletePaymentType/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => {
      console.log(err);
    });
