import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useState } from "react";
import { IRole } from "../../interfaces/role.interface";
import { getAllRolesAsync } from "../../services/role.service";

function RolesPage(): JSX.Element {
  const [datas, setDatas] = useState<IRole[]>([]);
  const [quickFilterText, setQuickFilterText] = useState("");

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllRolesAsync());
  }, []);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const [colDefs] = useState<ColDef<IRole>[]>([
    { headerName: "Id", field: "id" as keyof IRole },
    { headerName: "Name", field: "name" as keyof IRole },
  ]);
  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Roles</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Roles</div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<IRole>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RolesPage;
