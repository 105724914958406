import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import * as Yup from "yup";
import ActionsCopmonent from "../../components/actions";
import FormGroup from "../../components/formGroup";
import { getImageSrc, handleFileChange } from "../../hooks/file.hook";
import { IStreamer, IStreamerCreateDto, IStreamerUpdateDto } from "../../interfaces/streamer.interface";
import {
  createStreamerAsync,
  getAllStreamersAsync,
  getStreamerByIdAsync,
  toggleStreamerAsync,
  updateStreamerAsync,
} from "../../services/streamer.service";

const createSchema = Yup.object().shape({
  name: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  position: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  facebook: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  instagram: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  tiktok: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  description: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  image: Yup.mixed<File>().when((files, schema) => {
    if (files.some((obj) => obj !== null && files[0] instanceof File))
      return schema
        .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024) // 1MB
        .test(
          "fileFormat",
          "Unsupported Format",
          (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
        );
    else return schema.notRequired();
  }),
});

const updateSchema = Yup.object().shape({
  name: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  position: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  facebook: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  instagram: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  tiktok: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  description: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  image: Yup.mixed<File>().when((files, schema) => {
    if (files.some((obj) => obj !== null && files[0] instanceof File))
      return schema
        .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024) // 1MB
        .test(
          "fileFormat",
          "Unsupported Format",
          (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
        );
    else return schema.notRequired();
  }),
});

function StreamersPage(): JSX.Element {
  const [datas, setDatas] = useState<IStreamer[]>([]);
  const [data, setData] = useState<IStreamer | null>(null);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllStreamersAsync());
  }, []);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const handleDelete = useCallback(async (id: number) => {
    await toggleStreamerAsync(id);
    await getAllData();
  }, []);

  const handleEdit = useCallback(async (id: number) => {
    setData(await getStreamerByIdAsync(id));
    setUpdateModal(true);
  }, []);

  const [colDefs] = useState<ColDef<IStreamer>[]>([
    { headerName: "Id", field: "id" as keyof IStreamer },
    { headerName: "Name", field: "title" as keyof IStreamer },
    { headerName: "Position", field: "position" as keyof IStreamer },
    { headerName: "Facebook", field: "facebook" as keyof IStreamer },
    { headerName: "Instagram", field: "instagram" as keyof IStreamer },
    { headerName: "Tiktok", field: "tiktok" as keyof IStreamer },
    { headerName: "Description", field: "description" as keyof IStreamer },
    { headerName: "Created At", field: "createdAt" as keyof IStreamer },
    { headerName: "Is Disabled", field: "isDisabled" as keyof IStreamer },
    {
      headerName: "Image",
      field: "image" as keyof IStreamer,
      cellRenderer: (params: CustomCellRendererProps) =>
        params.value && (
          <img className="gridjs-search-img" src={getImageSrc("streamers", params.value)} alt="Streamer" />
        ),
    },
    {
      headerName: "Actions",
      field: "id" as keyof IStreamer,
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionsCopmonent id={params.value} onUpdate={handleEdit} onDelete={handleDelete} />
      ),
    },
  ]);
  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Streamers</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Streamers</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={(): void => setCreateModal(true)}>
              + Create Streamer
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<IStreamer>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={updateModal}
        onAfterClose={(): void => setUpdateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Update Streamer</h2>
          <div className="my-1">
            <Formik<IStreamerUpdateDto>
              initialValues={{
                name: data?.name ?? "",
                position: data?.position ?? "",
                facebook: data?.facebook ?? "",
                instagram: data?.instagram ?? "",
                tiktok: data?.tiktok ?? "",
                description: data?.description ?? "",
                image: null,
              }}
              validationSchema={updateSchema}
              onSubmit={async (
                values: IStreamerUpdateDto,
                formikHelpers: FormikHelpers<IStreamerUpdateDto>,
              ): Promise<void> => {
                if (!data?.id) return;
                const response = await updateStreamerAsync(data.id, values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setUpdateModal(false);
                  setData(null);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, initialValues, setFieldValue }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup
                    name="name"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.name}
                    label="Name"
                    placeholder="Name"
                  />
                  <FormGroup
                    name="position"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.position}
                    label="Position"
                    placeholder="Position"
                  />
                  <FormGroup
                    name="facebook"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.facebook}
                    label="Facebook"
                    placeholder="Facebook"
                  />
                  <FormGroup
                    name="instagram"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.instagram}
                    label="Instagram"
                    placeholder="Instagram"
                  />
                  <FormGroup
                    name="tiktok"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.tiktok}
                    label="Tiktok"
                    placeholder="Tiktok"
                  />
                  <FormGroup
                    name="description"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.description}
                    label="Description"
                    placeholder="Description"
                  />
                  <FormGroup
                    name="image"
                    label="Image"
                    type="file"
                    onChange={(event): void => handleFileChange(event, setFieldValue)}
                    accept="image/png, image/jpeg"
                    placeholder="Image"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setUpdateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Streamer</h2>
          <div className="my-1">
            <Formik<IStreamerCreateDto>
              initialValues={{
                name: "",
                position: "",
                facebook: "",
                instagram: "",
                tiktok: "",
                description: "",
                image: null,
              }}
              validationSchema={createSchema}
              onSubmit={async (
                values: IStreamerCreateDto,
                formikHelpers: FormikHelpers<IStreamerCreateDto>,
              ): Promise<void> => {
                const response = await createStreamerAsync(values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setCreateModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, setFieldValue }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-2">
                  <FormGroup name="name" type="text" onChange={handleChange} label="Name" placeholder="Name" />
                  <FormGroup
                    name="position"
                    type="text"
                    onChange={handleChange}
                    label="Position"
                    placeholder="Position"
                  />
                  <FormGroup
                    name="facebook"
                    type="text"
                    onChange={handleChange}
                    label="Facebook"
                    placeholder="Facebook"
                  />
                  <FormGroup
                    name="instagram"
                    type="text"
                    onChange={handleChange}
                    label="Instagram"
                    placeholder="Instagram"
                  />
                  <FormGroup name="tiktok" type="text" onChange={handleChange} label="Tiktok" placeholder="Tiktok" />
                  <FormGroup
                    name="description"
                    type="text"
                    onChange={handleChange}
                    label="Description"
                    placeholder="Description"
                  />
                  <FormGroup
                    name="image"
                    label="Image"
                    type="file"
                    onChange={(event): void => handleFileChange(event, setFieldValue)}
                    accept="image/png, image/jpeg"
                    placeholder="Image"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setCreateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </section>
  );
}

export default StreamersPage;
