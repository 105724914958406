import { useState } from "react";
import { TOKEN_STORAGE_NAME } from "../constants/const";

export const useToken = (): [string | null, (newToken: string) => void] => {
  const [token, setTokenInterval] = useState(() => localStorage.getItem(TOKEN_STORAGE_NAME));

  const setToken = (newToken: string): void => {
    localStorage.setItem(TOKEN_STORAGE_NAME, newToken);
    setTokenInterval(newToken);
  };

  return [token, setToken];
};