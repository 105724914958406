import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "../../auth/useUser";
import Footer from "../../layouts/footer";
import Navbar from "../../layouts/navbar";

function ProtectedRoute(): JSX.Element {
  const user = useUser();

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="page">
      <Navbar />
      <div className="main-content app-content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default ProtectedRoute;
