import { BASE_URL } from "../constants/const";

export const handleFileChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: unknown) => void,
): void => {
  const file = event.currentTarget.files?.[0] || null;
  setFieldValue("image", file);
};

export const handleFileChanges = (
  event: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: unknown) => void,
): void => {
  const files = event.currentTarget.files ? Array.from(event.currentTarget.files) : null;
  setFieldValue("images", files);
};

export const getImageSrc = (folderName: string, imageName: string): string => `${BASE_URL}/images/${folderName}/${imageName}` ?? "";
