import { Link } from "react-router-dom";
import { ReactComponent as DeleteSvg } from "../../assets/svgs/delete-bin-line.svg";
import { ReactComponent as DownloadSvg } from "../../assets/svgs/download-2-line.svg";
import { ReactComponent as EditSvg } from "../../assets/svgs/edit-line.svg";

interface ActionsComponentProps {
  id: number;
  toStock?: string;
  onStock?: (id: number) => Promise<void>;
  onUpdate?: (id: number) => Promise<void>;
  onDelete?: (id: number) => Promise<void>;
  onAtWork?: (id: number) => Promise<void>;
}

function ActionsCopmonent({ id, onUpdate, onDelete, toStock, onStock, onAtWork }: ActionsComponentProps): JSX.Element {
  return (
    <div className="d-flex gap-2 align-items-center h-100">
      {onUpdate && (
        <button className="btn btn-icon btn-sm btn-info-light">
          <EditSvg className="ri-edit-line" onClick={async (): Promise<void> => await onUpdate(id)} />
        </button>
      )}
      {onAtWork && (
        <button className="btn btn-icon btn-sm btn-info-light">
          <EditSvg className="ri-edit-line" onClick={async (): Promise<void> => await onAtWork(id)} />
        </button>
      )}
      {onStock && (
        <button className="btn btn-icon btn-sm btn-success">
          <DownloadSvg className="ri-edit-line" onClick={async (): Promise<void> => await onStock(id)} />
        </button>
      )}
      {toStock && (
        <Link to={toStock} className="btn btn-icon btn-sm btn-success">
          <DownloadSvg className="ri-edit-line" />
        </Link>
      )}
      {onDelete && (
        <button className="btn btn-icon btn-sm btn-danger-light">
          <DeleteSvg className="ri-delete-bin-line" onClick={async (): Promise<void> => await onDelete(id)} />
        </button>
      )}
    </div>
  );
}

export default ActionsCopmonent;
