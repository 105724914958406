import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { handleValidationErrors } from "../hooks/errorhandle.hook";
import { IRedeemCode, IRedeemCodesCreateDto } from "../interfaces/redeemcode.interface";

export const getAllRedeemCodesAsync = async (): Promise<IRedeemCode[]> =>
  await axiosInstance
    .get<IRedeemCode[]>("/RedeemCodes/GetRedeemCodes")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const createRedeemCodeAsync = async (
  postData: IRedeemCodesCreateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> =>
  await axiosInstance
    .post("/RedeemCodes/CreateRedeemCodes", { ...postData })
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });

export const toggleRedeemCodeAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("RedeemCodes/DeleteRedeemCode/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => {
      console.log(err);
    });
