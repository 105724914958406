import axiosInstance from "../configs/axios.config";
import { IRole } from "../interfaces/role.interface";

export const getAllRolesAsync = async (): Promise<IRole[]> =>
  await axiosInstance
    .get<IRole[]>("/Roles/GetRoles")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
