import { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ROUTES } from "./routers/router";

const router = createBrowserRouter(ROUTES);

function App(): JSX.Element {
  return (
    <>
      <RouterProvider router={router} />
      <Toaster />
    </>
  );
}

export default App;
