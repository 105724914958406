import toast from "react-hot-toast";
import axiosInstance from "../configs/axios.config";
import { handleValidationErrors } from "../hooks/errorhandle.hook";
import { IManufacturer, IManufacturerCreateDto, IManufacturerUpdateDto } from "../interfaces/manufacturer.interface";
import { getAntiForgeryTokenAsync } from "./auth.service";

export const getAllManufacturerAsync = async (): Promise<IManufacturer[]> =>
  await axiosInstance
    .get<IManufacturer[]>("/Manufacturers/GetManufacturers")
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

export const getManufacturerByIdAsync = async (id: number): Promise<IManufacturer> =>
  await axiosInstance
    .get<IManufacturer>("Manufacturers/GetManufacturer/" + id)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
      return {} as IManufacturer;
    });

export const createManufacturerAsync = async (
  postData: IManufacturerCreateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<number> => {
  const token = await getAntiForgeryTokenAsync();
  return await axiosInstance
    .post(
      "/Manufacturers/CreateManufacturer",
      { ...postData },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-XSRF-TOKEN": token,
        },
      },
    )
    .then((response) => {
      toast.success("Data created successfuly");
      return response.data;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return null;
    });
};

export const updateManufacturerAsync = async (
  id: number,
  putData: IManufacturerUpdateDto,
  setFieldError: (field: string, message: string | undefined) => void,
): Promise<boolean> => {
  const token = await getAntiForgeryTokenAsync();
  return await axiosInstance
    .put(
      "Manufacturers/UpdateManufacturer",
      { id, ...putData },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-XSRF-TOKEN": token,
        },
      },
    )
    .then(() => {
      toast.success("Data updated successfuly");
      return true;
    })
    .catch((error) => {
      if (error && error.isValidationError) {
        const { errors } = error;
        handleValidationErrors(errors, setFieldError);
      }

      return false;
    });
};

export const toggleManufacturereAsync = async (id: number): Promise<void> =>
  await axiosInstance
    .delete("Manufacturers/DeleteManufacturer/" + id)
    .then(() => {
      toast.success("Data toggled successfuly");
    })
    .catch((err) => {
      console.log(err);
    });
