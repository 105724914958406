import { ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import * as Yup from "yup";
import ActionsCopmonent from "../../components/actions";
import FormGroup from "../../components/formGroup";
import { getImageSrc, handleFileChange } from "../../hooks/file.hook";
import { IPaymentType, IPaymentTypeCreateDto, IPaymentTypeUpdateDto } from "../../interfaces/paymenttype.interface";
import {
  createPaymentTypeAsync,
  getAllPaymentTypesAsync,
  getPaymentTypeByIdAsync,
  togglePaymentTypeAsync,
  updatePaymentTypeAsync,
} from "../../services/paymenttype.service";

const createSchema = Yup.object().shape({
  name: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  code: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  owner: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  isImageRequest: Yup.boolean().required(),
  image: Yup.mixed<File>()
    .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024) // 1MB
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
    )
    .required("Required!"),
});

const updateSchema = Yup.object().shape({
  name: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  code: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  owner: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required!"),
  isImageRequest: Yup.boolean().required(),
  image: Yup.mixed<File>()
    .test("fileSize", "File size is too large", (value) => value != undefined && value.size <= 1024 * 1024) // 1MB
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => value != undefined && ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
    )
    .notRequired(),
});

function PaymentTypesPage(): JSX.Element {
  const [datas, setDatas] = useState<IPaymentType[]>([]);
  const [data, setData] = useState<IPaymentType | null>(null);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const getAllData = useCallback(async (): Promise<void> => {
    setDatas(await getAllPaymentTypesAsync());
  }, []);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const handleDelete = useCallback(async (id: number) => {
    await togglePaymentTypeAsync(id);
    await getAllData();
  }, []);

  const handleEdit = useCallback(async (id: number) => {
    setData(await getPaymentTypeByIdAsync(id));
    setUpdateModal(true);
  }, []);

  const [colDefs] = useState<ColDef<IPaymentType>[]>([
    { headerName: "Id", field: "id" as keyof IPaymentType },
    { headerName: "Name", field: "name" as keyof IPaymentType },
    { headerName: "Code", field: "code" as keyof IPaymentType },
    { headerName: "Owner", field: "owner" as keyof IPaymentType },
    { headerName: "Created At", field: "createdAt" as keyof IPaymentType },
    { headerName: "Is Image Request", field: "isImageRequest" as keyof IPaymentType },
    { headerName: "Is Disabled", field: "isDisabled" as keyof IPaymentType },
    {
      headerName: "Image",
      field: "image" as keyof IPaymentType,
      cellRenderer: (params: CustomCellRendererProps) =>
        params.value && (
          <img className="gridjs-search-img" src={getImageSrc("paymenttypes", params.value)} alt="Payment Type" />
        ),
    },
    {
      headerName: "Actions",
      field: "id" as keyof IPaymentType,
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionsCopmonent id={params.value} onUpdate={handleEdit} onDelete={handleDelete} />
      ),
    },
  ]);

  return (
    <section className="container">
      <div className="d-md-flex d-block align-items-center justify-content-between my-4">
        <h1 className="page-title fw-semibold fs-18 mb-0">Payment Types</h1>
      </div>
      <div className="card custom-card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="card-title">Payment Types</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={(): void => setCreateModal(true)}>
              + Create Payment Type
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="gridjs-head">
            <input
              className="gridjs-input gridjs-search-input"
              type="search"
              placeholder="Search..."
              value={quickFilterText}
              onChange={(e): void => setQuickFilterText(e.target.value)}
            />
          </div>
          <div className="p-0 ag-theme-quartz" style={{ height: "90%" }}>
            <AgGridReact<IPaymentType>
              rowData={datas}
              columnDefs={colDefs}
              pagination={true}
              paginationAutoPageSize={true}
              quickFilterText={quickFilterText}
              gridOptions={{
                autoSizeStrategy: {
                  type: "fitGridWidth",
                },
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={updateModal}
        onAfterClose={(): void => setUpdateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Update Payment Type</h2>
          <div className="my-1">
            <Formik<IPaymentTypeUpdateDto>
              initialValues={{
                name: data?.name ?? "",
                code: data?.code ?? "",
                owner: data?.owner ?? "",
                isImageRequest: data?.isImageRequest ?? false,
                image: null,
              }}
              validationSchema={updateSchema}
              onSubmit={async (
                values: IPaymentTypeUpdateDto,
                formikHelpers: FormikHelpers<IPaymentTypeUpdateDto>,
              ): Promise<void> => {
                if (!data?.id) return;
                const response = await updatePaymentTypeAsync(data.id, values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setUpdateModal(false);
                  setData(null);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, initialValues, setFieldValue }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-3">
                  <FormGroup
                    name="name"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.name}
                    label="Name"
                    placeholder="Name"
                  />
                  <FormGroup
                    name="code"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.code}
                    label="Code"
                    placeholder="Code"
                  />
                  <FormGroup
                    name="owner"
                    type="text"
                    onChange={handleChange}
                    defaultValue={initialValues.owner}
                    label="Owner"
                    placeholder="Owner"
                  />
                  <FormGroup
                    name="isImageRequest"
                    type="checkbox"
                    onChange={handleChange}
                    defaultChecked={initialValues.isImageRequest}
                    label="Is Image Request"
                    placeholder="Is Image Request"
                  />
                  <FormGroup
                    name="image"
                    label="Image"
                    type="file"
                    onChange={(event): void => handleFileChange(event, setFieldValue)}
                    accept="image/png, image/jpeg"
                    placeholder="Image"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setUpdateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={createModal}
        onAfterClose={(): void => setCreateModal(false)}
        overlayClassName="modal-overlay"
        className="modal"
      >
        <div>
          <h2>Create New Payment Type</h2>
          <div className="my-1">
            <Formik<IPaymentTypeCreateDto>
              initialValues={{
                name: "",
                code: "",
                owner: "",
                isImageRequest: false,
                image: null,
              }}
              validationSchema={createSchema}
              onSubmit={async (
                values: IPaymentTypeCreateDto,
                formikHelpers: FormikHelpers<IPaymentTypeCreateDto>,
              ): Promise<void> => {
                const response = await createPaymentTypeAsync(values, formikHelpers.setFieldError);

                if (response) {
                  await getAllData();
                  setCreateModal(false);
                  formikHelpers.resetForm();
                }
              }}
            >
              {({ handleSubmit, isSubmitting, handleChange, setFieldValue }): JSX.Element => (
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-wrap gap-2">
                  <FormGroup name="name" type="text" onChange={handleChange} label="Name" placeholder="Name" />
                  <FormGroup name="code" type="text" onChange={handleChange} label="Code" placeholder="Code" />
                  <FormGroup name="owner" type="text" onChange={handleChange} label="Owner" placeholder="Owner" />
                  <FormGroup
                    name="isImageRequest"
                    type="checkbox"
                    onChange={handleChange}
                    label="Is Image Request"
                    placeholder="Is Image Request"
                  />
                  <FormGroup
                    name="image"
                    label="Image"
                    type="file"
                    onChange={(event): void => handleFileChange(event, setFieldValue)}
                    accept="image/png, image/jpeg"
                    placeholder="Image"
                  />
                  <div className="d-flex flex-row-reverse gap-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      disabled={isSubmitting}
                      onClick={(): void => setCreateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </section>
  );
}

export default PaymentTypesPage;
